import SafeAreaHeader from "../../components/safeAreaHeader";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LuChevronLeft } from "react-icons/lu";
import { ContactSupportComponent } from "../../../doctor/pages/contact-us";

export const ContactSupportWalkthrough = () => {


  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <di>
      <SafeAreaHeader
        leftIcon={<LuChevronLeft size={24} color={"#555555"} />}
        leftIconOnclick={() => {
          goBack();
        }}
      />
      <div style={{padding: 25}}>

        <ContactSupportComponent />
      </div>
    </di>
  );
};

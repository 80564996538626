import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Icon, UserAvatar } from "../../../../components/Component";
import User from "../../../../images/avatar/b-sm.jpg";
import { FiChevronLeft, FiMapPin, FiPhone } from "react-icons/fi";
import axios from "axios";
import { IoClose } from "react-icons/io5";
import { RiCheckFill } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import { EmptyState } from "../../../../components/fouladyar/empty-state/emptyState";
import EmptyStateImage from "../../../../assets/images/cliconsult/empty-states/session-empty-state.png";
import {DateTime} from 'luxon';
import { HiOutlineCreditCard } from "react-icons/hi";
import { MdOutlineClose, MdOutlineHealthAndSafety } from "react-icons/md";
import { convertToTitleCase } from "../../../../shared/textTools";
import toast from "react-hot-toast";
import { ErrorToaster } from "../../../../shared/toaster";
import { deleteTicketByAppointmentId } from "../../../../redux/store/services/ticket/store";
import { BiChevronLeft } from "react-icons/bi";
import SafeAreaHeader from "../../components/safeAreaHeader";
import ModalHelper from "../../../../components/fouladyar/modal-helper/modalHelper";

import CopyToClipboard from "react-copy-to-clipboard";
import { LuCopy } from "react-icons/lu";

const DoctorProfile = () => {
  const auth = useSelector((state) => state.auth);
  const ticket = useSelector((state) => state.ticket);
  const mode = useSelector((state) => state.search.mode);
  const search = useSelector((state) => state.search);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false)
  const [profile, setProfile] = useState({

  })
  const appointmentId = "abc";
  const [nextAppointmentTime, setNextAppointmentTime] = useState("")
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalComponent, setModalComponent] = useState(<div>empty</div>);
  const [betterMatchId, setBetterMatchIdId] = useState("")
  const [sessions, setSessions] = useState([
    // {
    //   "id": "515975963881802057",
    //   "date": "2023-11-24",
    //   "start": "20:45",
    //   "end": "20:46",
    //   "length": 3,
    //   "status": "EXPIRED"
    // },
    // {
    //   "id": "515976037328710614",
    //   "date": "2023-11-24",
    //   "start": "21:01",
    //   "end": "21:30",
    //   "length": 4,
    //   "status": "FULL"
    // },
    // {
    //   "id": "515976004310737966",
    //   "date": "2023-11-24",
    //   "start": "20:47",
    //   "end": "21:00",
    //   "length": 4,
    //   "status": "IN_PROGRESS"
    // },
    // {
    //   "id": "515976037328710614",
    //   "date": "2023-11-24",
    //   "start": "21:01",
    //   "end": "21:30",
    //   "length": 4,
    //   "status": "INITIALIZED"
    // },
  ])
  const { id } = useParams();



  // Function to convert time to French time zone
  function convertToTime(timeString) {
    const [hours, minutes] = timeString.split(':');

    const date = new Date();
    date.setHours(parseInt(hours, 10));
    date.setMinutes(parseInt(minutes, 10));

    return date
  }


  // Function to find the better match
  async function findBetterMatch(sessions) {
    const parisTime = new Date( new Date().toLocaleString('en-US', { timeZone: 'Europe/Paris' }));
    return sessions.find((event) => {
      // const startTime = DateTime.fromFormat(event.start, 'HH:mm', { zone: 'Europe/Paris' });
      const startTime = convertToTime(event.start);
      const endTime = convertToTime(event.end);

      if (startTime < parisTime && endTime > parisTime) {
        return event.id;
      }
    });
  }

  function ShareMessage({link}){
    return(
      <div>
        <div className="d-flex flex-row justify-between">
          <div className="col-sm-auto align-center">
            <h4 style={{ padding: "0px 20px", fontSize: 15 }}>Partager</h4>
          </div>
          <div className="col-sm-auto">
            <div className="btn" onClick={()=>{}}>
              <a href="#cancel" className="close" style={{fontSize: "1.1em"}}>
                <Icon style={{color: "#000"}} name="cross-sm" onClick={(ev) => {
                  ev.preventDefault();
                  setIsModalOpen(false)
                }}
                ></Icon>
              </a>
            </div>
          </div>
        </div>
        <li className="divider" style={{margin: 0}}></li>

        {/*<div className="share-link-style" >*/}
        {/*  {link}*/}
        {/*</div>*/}

        <div className="d-flex justify-center align-center p-2" style={{ margin: "0px 0px 40px 0px !important" }}>
          <div className={`btn blue-button`} onClick={() => {

            toast.success('Le lien du cabinet médical virtuel a été copié avec succès')
            setIsModalOpen(false)
          }}>
            <LuCopy size={20} color={"#ffffff"} className="me-2" /> Copier le lien
          </div>
        </div>

      </div>
    )
  }


  async function loadProfile() {
    try {
      setIsLoading(true)
      const ProfileResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/provider/account/${id}`, {
        headers: { "authorization": `bearer ${auth.token}` }
      });

      if (ProfileResponse.status === 200 ) {
        setProfile(ProfileResponse.data)
      }
    } catch (e) {
      ErrorToaster(e);
    }



    try {
      const SessionsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/session/${id}`, {
      });

      if (SessionsResponse.status === 200 ) {
        setSessions(SessionsResponse.data)
        if(SessionsResponse.data.length > 0){
          setBetterMatchIdId(await findBetterMatch(SessionsResponse.data).id)
        }
      }

    }catch (e){
      setSessions([])
      // ErrorToaster(e);
    }

    try {
      const nextAppointmentTimeResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/provider/appointment/next/${id}`, {
      });

      if (nextAppointmentTimeResponse.status === 200 ) {
        setNextAppointmentTime(nextAppointmentTimeResponse.data.time)
      }

    }catch (e){
      setNextAppointmentTime('')
      // ErrorToaster(e);
    }
  }

  useEffect(()=>{
    loadProfile();
  }, [])

  useEffect(()=>{
    loadProfile();
  }, [id])

  useEffect(()=>{
    console.log('search.selectedItem', id, search.selectedItem)
    if(search.selectedItem.provider_id)
      navigate(`/doctor-profile/${search.selectedItem.provider_id}`);
    if(search.selectedItem.providerSubjectId)
      navigate(`/doctor-profile/${search.selectedItem.providerSubjectId}`);
  }, [search.selectedItem])
  function Profile(){
    return(
      <div className="container">

          <div className="row  d-flex flex-row  justify-center align-center">
            <div className="col-sm-auto user-avatar-flex" style={{paddingLeft: 0}}>
              <UserAvatar className="profile-avatar" image={profile.image ? `${process.env.REACT_APP_S3_BUCKET}/${profile.image}` : User}></UserAvatar>
            </div>
          </div>

          <div className="row d-flex">
            <h6 className="profile-title p-1 text-center" >{ `${profile.gender === "MALE" ? "M. " : (profile.gender === "FEMALE" ? "Mme " : "Inconnu ")}${profile.doctorOrProfessor === "DOCTOR" ? 'Dr. ' : (profile.doctorOrProfessor === "PROFESSOR" ? "Prof. " : "")}${profile.firstName} ${profile.lastName}`}</h6>
          </div>

          <div className="row">
            <p className={`search-item-expertise ${profile.type?.toLowerCase() || 'doctor'}-color text-center`}>{t(profile.specialization)}</p>
          </div>

        <div className=" d-flex flex-row justify-content-evenly">
          <div className=" d-flex flex-row justify-center  align-center">
            <div  className="col-sm-auto w-auto">
              <HiOutlineCreditCard   size={20} color={"#333333"}/>
            </div>
            <p  className="col-sm-auto p-0 bold pe-1 m-0 w-auto" style={{fontWeight: "700"}}>Paiement: </p>
            <p className="col-sm-auto p-0  w-auto">{profile.paymentTypes?.map(i => t(i)).join(' / ')}</p>
          </div>

        </div>
        <div>

          <div className=" d-flex flex-row justify-center align-center">
            <div  className="col-sm-auto w-auto">
              <MdOutlineHealthAndSafety   size={20} color={"#333333"}/>
            </div>
            <p  className="col-sm-auto p-0  pe-1 m-0 bold w-auto" style={{fontWeight: "700"}}>cate vitale: </p>
            <p className="col-sm-auto p-0  w-auto">{profile.acceptVitalCard ? 'Oui' : 'Non'}</p>
          </div>
        </div>

          <div className="row d-flex">
            <div className="doctor-badge  p-1 col" style={{paddingLeft: 0}}>
              <div className="d-flex flex-row  justify-center align-center">
                {!profile.canDoPediatrics ? '' : <div className="search-item-badge horizontal-padding">{t('canDoPediatrics')}</div>}
                {!profile.canDoAbortion ? '' : <div className="search-item-badge horizontal-padding">{t('canDoAbortion')}</div>}
                {!profile.canDoUltrasound ? '' : <div className="search-item-badge horizontal-padding">{t('canDoUltrasound')}</div>}
              </div>
            </div>
          </div>


        <p className={`mt-2 mb-0 text-center`}>Les Langues parlées :</p>
          <div className="row d-flex">
            <div className="doctor-badge col p-0">
              <div className="d-flex flex-row flex-wrap justify-center align-center">
                {
                  profile.languages?.map(i => {
                    return(
                      <div className={`search-item-badge mb-2 horizontal-padding`}>{t(i)}</div>
                    )
                  })
                }
              </div>
            </div>
          </div>

          <div className="row d-flex">
            <p className={`search-item-expertise ${profile.type?.toLowerCase() || 'doctor'}-color p-1 text-center`}>
              {profile.note}
            </p>
          </div>

          <div className="search-item-location d-flex flex-row justify-center align-center" onClick={() => {
            window.ReactNativeWebView.postMessage(JSON.stringify({ action: 'launch', message: {type: 'map', value: profile.address }}));
          }}>
            <div className="col-sm-auto w-auto pe-2">
              <FiMapPin  size={18} color={"#333333"}/>
            </div>
            {/*<p className="col-sm-auto p-0 w-auto address-link">*/}
              <div className="col-sm-auto p-0 w-auto address-link" onClick={async ()=>{
                const link= `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(profile.address || '-')}`;
                window.open(link, '_blank');
              }}>
                {profile.address || '-'}
              </div>
            {/*</p>*/}
          </div>

          <div className="search-item-location d-flex flex-row  justify-center align-center" onClick={()=>{
            window.ReactNativeWebView.postMessage(JSON.stringify({ action: 'launch', message: {type: 'tel', value: profile.officePhoneNumber }}));
          }}>
            <div  className="col-sm-auto  w-auto pe-2">
              <FiPhone size={18} color={"#333333"}/>
            </div>
            <p className="col-sm-auto p-0 w-auto mobile-phone-link">
              <a href={`tel:${profile?.officePhoneNumber || '-'}`} className="phone-link">
                {profile?.officePhoneNumber || '-'}
              </a>
            </p>
          </div>

      </div>
    )
  }


  function Header(){
    return(
      <>
        <div className="container">
          <div className="row d-flex justify-start align-start p-0 pb-2 pt-2">
            <Link to={`${process.env.PUBLIC_URL}/home`} className="p-0 m-0">
              <FiChevronLeft size={22} color={"#757575"} />
            </Link>
          </div>
        </div>
      </>
    )
  }

  function Reservations() {
    return (

      <div className="reservations-container">

        {/*{*/}
        {/*  "id": "515975963881802057",*/}
        {/*  "date": "2023-11-24",*/}
        {/*  "start": "20:45",*/}
        {/*  "end": "20:46",*/}
        {/*  "length": 3,*/}
        {/*  "status": "EXPIRED"*/}
        {/*},*/}
        {
          sessions.map((i, index) => {
            if(i.status === "EXPIRED" || i.status === "FULL"){
              return(
                <div className={`reservation-row past ${sessions.length === 1 ? 'hide-line' : ''}`}>
                  <div className="circle">
                    <IoClose  size={18} color={"#757575"} />
                  </div>

                  <div className="reservation-details">
                    <div className="title"></div>
                    <div className="reservation-period">{i.start} - {i.end}</div>
                  </div>
                </div>
              )
            }


            if(i.status === "IN_PROGRESS" || (i.status === 'INITIALIZED' && i.id === sessions.filter(j=> j.status === "INITIALIZED")[0].id && (sessions.filter(j=> j.status === "IN_PROGRESS")).length === 0 ) ){
              return(
                <div className={`reservation-row now ${mode}-background`}>
                  <div className="circle">
                    <RiCheckFill  size={18} color={"#757575"} />
                  </div>
                  <div className="reservation-details">
                    <div className="title">Créneau disponible</div>
                    <div className="reservation-period">{i.start} - {i.end}</div>
                  </div>
                </div>
              )
            }


            if(i.status === "INITIALIZED" || i.status === "FULL"){
              return(
                <div className="reservation-row future">
                  <div className="circle"></div>
                  <div className="reservation-details">
                    <div className="title"></div>
                    <div className="reservation-period">{i.start} - {i.end}</div>
                  </div>
                </div>
              )
            }

          })
        }
      </div>
    );
  };

  async function checkIfThereIsActiveTicket() {
    try {
      const takenTicket = ticket.active.find(item => item.providerType === mode.toUpperCase())

      if(takenTicket){

        const availableActiveTickets = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/client/appointment/${takenTicket.providerSubjectId}/${takenTicket.appointmentId}`, {
          headers: { "authorization": `bearer ${auth.token}` }
        });

        if(availableActiveTickets.status === 200){
          if(availableActiveTickets.data.status === 'WAITING'){
            toast.error('Vous avez déjà un rendez-vous , \n' +
              'Pour prendre un nouveau rendez-vous, veuillez d\'abord annuler votre rendez-vous actuel !')
            return  true
          }else{
            dispatch(deleteTicketByAppointmentId(availableActiveTickets.data.appointmentId))
            return false
          }
        }else{
          dispatch(deleteTicketByAppointmentId(availableActiveTickets.data.appointmentId))
        }

      }

      return false
    }catch (e){
      ErrorToaster(e);
    }
    //
    // if(!(availableActiveTickets.data.status === "WAITING" || availableActiveTickets.data.status === "VISITING")){
    //   dispatch(deleteTicketByAppointmentId(appointmentId));
    //   //تیکت مورد نظر شما به دلیل منقضی شدن از سیستم حذف شد
    //   toast.error('Le billet souhaité a été supprimé du système en raison de son expiration')
    //   return false
    // }
    //
    // return true
  }
  function copyToClipboard(text) {
    const textField = document.createElement('textarea');
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    document.body.removeChild(textField);
  }
  return (
    <>
      <ModalHelper
        size={'sm'}
        open={isModalOpen}
        onOpen={() => setIsModalOpen(true)}
        onClose={() => setIsModalOpen(false)}
        component={modalComponent}
      />

      <SafeAreaHeader
        leftIcon={<BiChevronLeft size={28} color={"#555555"} />}
        leftIconOnclick={()=>{
          navigate(-1);
        }}
      />
      <div className="scroll-fixed-area" >
        <div className="scroll-container">
          <Profile/>

          {
            sessions.length === 0 ?
              <EmptyState className={"empty-state-container-profile"} title={"Il n’y a pas de créneau de disponible pour aujourd'hui."} image={EmptyStateImage}/> :
              <Reservations/>
          }

          <div className={`btn blue-button ${(sessions.length === 0 || (sessions?.filter(i=> i.status === "IN_PROGRESS" || i.status === "INITIALIZED")).length === 0 ) ? 'disabled' : ''}`} onClick={async () => {
            if((sessions.length > 0 || (sessions?.filter(i=> i.status === "IN_PROGRESS" || i.status === "INITIALIZED")).length > 0 )){
              if(!(await checkIfThereIsActiveTicket())){
                navigate(`/doctor-take-appointment-starter/${id}?appointmentId=${appointmentId}`)
              }
            }



          }}>
            Prendre un rendez-vous <div className={"seyedi_green"}>{nextAppointmentTime}</div>
          </div>

          <div className={`btn white-button`} onClick={() => {
            // copyToClipboard(link)
            // toast.success('Le Lien a été copié avec succès')
          }}>



            {/*<LuCopy size={20} color={"#000000"} className="me-2" /> Copier le lien*/}




            <div className='d-flex flex-row justify-content-around'>
              <div className=" m-0 me-2">
                Envoyer le lien vers
              </div>
              <div className={"telegram m-0 me-2"} onClick={async ()=>{
                const message = `Bureau virtuel du ${profile.gender === "MALE" ? "M. " : (profile.gender === "FEMALE" ? "Mme " : "Inconnu ")}${profile.doctorOrProfessor === "DOCTOR" ? 'Dr. ' : (profile.doctorOrProfessor === "PROFESSOR" ? "Prof. " : "")}${convertToTitleCase(profile.firstName)} ${convertToTitleCase(profile.lastName)}: \n\n${process.env.PUBLIC_URL}/doctor-profile/${id}`;
                const telegramLink = `https://t.me/share/url?url=${encodeURIComponent(message)}`;



                if (window.ReactNativeWebView && navigator.userAgent.toLowerCase().includes('android')) {
                  //Android
                  window.ReactNativeWebView?.postMessage(JSON.stringify({ action: 'share', message: message}));
                }else {
                  try {

                    //Mobile (Browsers and IOS)
                    await navigator.share({
                      title: message,
                      text: message,
                      url: telegramLink,
                    })
                  }catch (e) {
                    //Desktop Browsers
                    window.open(telegramLink, '_blank');
                  }
                }


              }}>
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_2256_18850)">
                    <path d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z" fill="url(#paint0_linear_2256_18850)"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.79174 14.8429C11.1645 12.9377 14.0805 11.6817 15.5394 11.0749C19.705 9.34231 20.5707 9.04132 21.1348 9.03135C21.2589 9.02919 21.5363 9.05995 21.716 9.20579C21.9826 9.42212 21.9842 9.89169 21.9546 10.2025C21.7288 12.5743 20.7521 18.3302 20.2551 20.9867C20.0448 22.1108 19.6308 22.4877 19.23 22.5246C18.359 22.6047 17.6975 21.9489 16.8538 21.3959C15.5336 20.5304 14.7878 19.9917 13.5063 19.1473C12.0253 18.1713 12.9854 17.635 13.8294 16.7583C14.0503 16.529 17.8882 13.038 17.9625 12.7213C17.9718 12.6817 17.9805 12.534 17.8927 12.4561C17.805 12.3782 17.6756 12.4048 17.5822 12.426C17.4498 12.456 15.3407 13.85 11.2551 16.6079C10.6565 17.019 10.1142 17.2193 9.62844 17.2088C9.09284 17.1973 8.06264 16.906 7.29676 16.657C6.35739 16.3517 5.61079 16.1902 5.67582 15.6717C5.70969 15.4015 6.08164 15.1253 6.79174 14.8429Z" fill="white"/>
                  </g>
                  <defs>
                    <linearGradient id="paint0_linear_2256_18850" x1="15" y1="0" x2="15" y2="29.7656" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#2AABEE"/>
                      <stop offset="1" stop-color="#229ED9"/>
                    </linearGradient>
                    <clipPath id="clip0_2256_18850">
                      <rect width="30" height="30" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>

              </div>
              <div className={"whatsapp m-0 me-2"} onClick={async ()=>{
                const message = `Bureau virtuel du ${profile.gender === "MALE" ? "M. " : (profile.gender === "FEMALE" ? "Mme " : "Inconnu ")}${profile.doctorOrProfessor === "DOCTOR" ? 'Dr. ' : (profile.doctorOrProfessor === "PROFESSOR" ? "Prof. " : "")}${convertToTitleCase(profile.firstName)} ${convertToTitleCase(profile.lastName)}: \n\n${process.env.PUBLIC_URL}/doctor-profile/${id}`;
                const whatsappLink  = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`;

                if (window.ReactNativeWebView && navigator.userAgent.toLowerCase().includes('android')) {
                  //Android
                  window.ReactNativeWebView?.postMessage(JSON.stringify({ action: 'share', message: message }));
                }else {

                  try {
                    //Mobile (Browsers and IOS)
                    await navigator.share({
                      title: message,
                      text: message,
                      url: whatsappLink,
                    })
                  }catch (e) {
                    //Desktop Browsers
                    window.open(whatsappLink , '_blank');
                  }
                }

              }}>
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_2256_18854)">
                    <path d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z" fill="#2AA81A"/>
                    <path d="M20.2943 9.70908C19.0461 8.44676 17.3877 7.67229 15.6186 7.52549C13.8494 7.3787 12.0861 7.86926 10.6469 8.9086C9.20777 9.94794 8.18762 11.4676 7.77066 13.1931C7.35371 14.9187 7.56742 16.7365 8.37322 18.3183L7.58375 22.1512C7.5753 22.1895 7.57497 22.2291 7.58279 22.2675C7.59061 22.3059 7.6064 22.3423 7.62914 22.3742C7.66172 22.4226 7.70828 22.4598 7.76259 22.481C7.81691 22.5021 7.8764 22.5062 7.93309 22.4926L11.6831 21.6025C13.2606 22.3872 15.0653 22.5866 16.776 22.1654C18.4868 21.7441 19.9926 20.7295 21.0253 19.3021C22.0581 17.8747 22.5509 16.1271 22.4159 14.3705C22.281 12.6138 21.527 10.962 20.2884 9.70908H20.2943ZM19.1219 19.0584C18.2587 19.9201 17.147 20.4896 15.9434 20.6868C14.7397 20.884 13.5045 20.699 12.4114 20.1578L11.8864 19.8972L9.58309 20.442V20.4124L10.0686 18.0893L9.81204 17.5841C9.25313 16.488 9.05579 15.2431 9.24833 14.0279C9.44087 12.8128 10.0134 11.6898 10.8837 10.8203C11.9766 9.72856 13.4581 9.11534 15.0028 9.11534C16.5475 9.11534 18.0291 9.72856 19.1219 10.8203L19.1476 10.8558C20.2262 11.951 20.8283 13.428 20.8228 14.9652C20.8172 16.5023 20.2045 17.975 19.118 19.0624L19.1219 19.0584Z" fill="white"/>
                    <path d="M18.9187 17.3712C18.6364 17.8153 18.1884 18.3581 17.6298 18.4943C16.643 18.7311 15.141 18.4943 13.2601 16.7555L13.2364 16.7337C11.6002 15.2061 11.166 13.9331 11.2627 12.9245C11.32 12.3502 11.7976 11.8331 12.2002 11.4936C12.2643 11.4396 12.34 11.4014 12.4215 11.3819C12.5029 11.3624 12.5878 11.3623 12.6693 11.3815C12.7508 11.4006 12.8267 11.4386 12.8909 11.4924C12.9551 11.5461 13.0058 11.6141 13.0391 11.691L13.645 13.0607C13.6845 13.1489 13.6994 13.2462 13.6879 13.3421C13.6764 13.4381 13.6391 13.5291 13.5799 13.6055L13.272 14.0002C13.208 14.0821 13.1698 14.1813 13.1625 14.285C13.1551 14.3887 13.1788 14.4922 13.2305 14.5824C13.523 15.0135 13.8729 15.4026 14.2706 15.739C14.6986 16.1508 15.1839 16.4985 15.7114 16.7712C15.8077 16.8101 15.9134 16.8193 16.015 16.7979C16.1165 16.7764 16.2094 16.7253 16.2818 16.6508L16.6351 16.2916C16.7023 16.2193 16.7883 16.1671 16.8834 16.1406C16.9785 16.1141 17.079 16.1145 17.1739 16.1416L18.6147 16.5541C18.6964 16.5774 18.7714 16.6195 18.8337 16.6771C18.8961 16.7347 18.944 16.8062 18.9736 16.8857C19.0032 16.9653 19.0136 17.0507 19.0041 17.135C18.9945 17.2194 18.9653 17.3003 18.9187 17.3712Z" fill="white"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_2256_18854">
                      <rect width="30" height="30" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>

              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DoctorProfile;

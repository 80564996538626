import React, { useEffect, useState } from "react";
import menu, { floorMenuItems } from "./MenuData";
import { NavLink, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { clearToken } from "../../redux/store/services/auth/store";
import { useDispatch, useSelector } from "react-redux";
import { clearProfile } from "../../redux/store/services/profile/store/profile-actions";
import { useThemeUpdate } from '../provider/Theme';

const MenuHeading = ({ heading }) => {
  return (
    <li className="nk-menu-heading">
      <h6 className="overline-title text-primary-alt">{heading}</h6>
    </li>
  );
};

const MenuItem = ({ icon, link, text, isDivider, isSignout, sub, newTab, mobileView, sidebarToggle, badge, ...props }) => {
  let currentUrl;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const themeUpdate = useThemeUpdate();
  const toggleActionSidebar = (e) => {
    if (!sub && !newTab && mobileView) {
      sidebarToggle(e);
    }
  };

  if (window.location.pathname !== undefined) {
    currentUrl = window.location.pathname;
  } else {
    currentUrl = null;
  }
  const handleSignout = () => {
    localStorage.removeItem("accessToken");
    dispatch(clearToken())
    dispatch(clearProfile())
    navigate(`/login`);
  };
  const menuHeight = (el) => {
    var totalHeight = [];
    for (var i = 0; i < el.length; i++) {
      var margin =
        parseInt(window.getComputedStyle(el[i]).marginTop.slice(0, -2)) +
        parseInt(window.getComputedStyle(el[i]).marginBottom.slice(0, -2));
      var padding =
        parseInt(window.getComputedStyle(el[i]).paddingTop.slice(0, -2)) +
        parseInt(window.getComputedStyle(el[i]).paddingBottom.slice(0, -2));
      var height = el[i].clientHeight + margin + padding;
      totalHeight.push(height);
    }

    totalHeight = totalHeight.reduce((sum, value) => (sum += value));
    return totalHeight;
  };

  const makeParentActive = (el, childHeight) => {
    let element = el.parentElement.parentElement.parentElement;
    let wrap = el.parentElement.parentElement;
    if (element.classList[0] === "nk-menu-item") {
      element.classList.add("active");
      const subMenuHeight = menuHeight(el.parentNode.children);
      wrap.style.height = subMenuHeight + childHeight - 50 + "px";
      makeParentActive(element);
    }
  };

  useEffect(() => {
    var element = document.getElementsByClassName("nk-menu-item active current-page");
    var arrayElement = [...element];

    arrayElement.forEach((dom) => {
      if (dom.parentElement.parentElement.parentElement.classList[0] === "nk-menu-item") {
        dom.parentElement.parentElement.parentElement.classList.add("active");
        const subMenuHeight = menuHeight(dom.parentNode.children);
        dom.parentElement.parentElement.style.height = subMenuHeight + "px";
        makeParentActive(dom.parentElement.parentElement.parentElement, subMenuHeight);
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const menuToggle = (e) => {
    e.preventDefault();
    var self = e.target.closest(".nk-menu-toggle");
    var parent = self.parentElement;
    var subMenu = self.nextSibling;
    var subMenuItem = subMenu.childNodes;
    var parentSiblings = parent.parentElement.childNodes;
    var parentMenu = parent.closest(".nk-menu-wrap");
    //For Sub Menu Height
    var subMenuHeight = menuHeight(subMenuItem);

    // Get parent elements
    const getParents = (el) => {
      let parentSelector = document.querySelector(".nk-menu-md");
      if (parentSelector === undefined) {
        parentSelector = document;
      }
      var parents = [];
      var p = el.parentNode;
      while (p !== parentSelector) {
        var o = p;
        parents.push(o);
        p = o.parentNode;
      }
      parents.push(parentSelector);
      return parents;
    };

    var parentMenus = getParents(self);
    if (!parent.classList.contains("active")) {
      // For Parent Siblings
      for (var j = 0; j < parentSiblings.length; j++) {
        parentSiblings[j].classList.remove("active");
        if (typeof parentSiblings[j].childNodes[1] !== "undefined") {
          parentSiblings[j].childNodes[1].style.height = 0;
        }
      }
      if (parentMenu !== null) {
        if (!parentMenu.classList.contains("sub-opened")) {
          parentMenu.classList.add("sub-opened");

          for (var l = 0; l < parentMenus.length; l++) {
            if (typeof parentMenus !== "undefined") {
              if (parentMenus[l].classList.contains("nk-menu-wrap")) {
                parentMenus[l].style.height = subMenuHeight + parentMenus[l].clientHeight + "px";
              }
            }
          }
        }
      }
      // For Current Element
      parent.classList.add("active");
      subMenu.style.height = subMenuHeight + "px";
    } else {
      parent.classList.remove("active");
      if (parentMenu !== null) {
        parentMenu.classList.remove("sub-opened");
        for (var k = 0; k < parentMenus.length; k++) {
          if (typeof parentMenus !== "undefined") {
            if (parentMenus[k].classList.contains("nk-menu-wrap")) {
              parentMenus[k].style.height = parentMenus[k].clientHeight - subMenuHeight + "px";
            }
          }
        }
      }
      subMenu.style.height = 0;
    }
  };

  const menuItemClass = classNames({
    "nk-menu-item": true,
    "has-sub": sub,
    "active current-page": currentUrl === process.env.PUBLIC_URL + link
  });
  return (
    <li className={menuItemClass} onClick={(e) => toggleActionSidebar(e)}>

      {
        isSignout ?
          <div
            style={{ padding: "0.325rem 0rem", display: "flex", textAlign: 'left'}}
            className={`btn nk-menu-link${sub ? " nk-menu-toggle" : ""}`}
            onClick={handleSignout}
          >
            {
              isDivider ? <div className="divider list-menu-font-divider" ></div> :
                <>
                  <div className="pe-1">{icon ? icon : null}</div>
                  <span className="nk-menu-text menu-item-text">{text}</span>
                  {badge && <span className="nk-menu-badge">{badge}</span>}
                </>
            }
          </div> :
          <NavLink
            to={isDivider ? null : `${process.env.PUBLIC_URL + link}`}
            className={`nk-menu-link${sub ? " nk-menu-toggle" : ""}`}
            onClick={sub ? menuToggle : themeUpdate.sidebarVisibility}
          >
            {
              isDivider ? <div className="divider list-menu-font-divider"></div> :
                <>
                  <div className="pe-1">{icon ? icon : null}</div>
                  <span className="nk-menu-text menu-item-text">{text}</span>
                  {badge && <span className="nk-menu-badge">{badge}</span>}
                </>
            }
          </NavLink>
      }

      {sub ? (
        <div className="nk-menu-wrap">
          <MenuSub sub={sub} sidebarToggle={sidebarToggle} mobileView={mobileView} />
        </div>
      ) : null}
    </li>
  );
};

const MenuSub = ({ icon, link, text, sub, sidebarToggle, mobileView, ...props }) => {
  return (
    <ul className="nk-menu-sub" style={props.style}>
      {sub.map((item) => (
        <MenuItem
          link={item.link}
          icon={item.icon}
          text={item.text}
          sub={item.subMenu}
          isDivider={item.isDivider}
          key={item.text}
          badge={item.badge}
          newTab={item.newTab}
          sidebarToggle={sidebarToggle}
          mobileView={mobileView}
        />
      ))}
    </ul>
  );
};

const Menu = ({ sidebarToggle, mobileView }) => {

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    // Function to update screenWidth when the window is resized
    const updateScreenWidth = () => {
      setScreenWidth(window.innerWidth);
    };


    // Add event listener for window resize
    window.addEventListener('resize', updateScreenWidth);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', updateScreenWidth);
    };
  }, []);

  return (
    <ul className="nk-menu nk-menu-md">
      {menu.map((item) =>

        item.heading ? (
          <MenuHeading heading={item.heading} key={item.heading} />
        ) : (
          item.text !== "Applications" && (
            ((item.useInMobile && screenWidth < 1000) || !item.useInMobile) ?
              <MenuItem
                key={item.text}
                link={item.link}
                icon={item.icon}
                text={item.text}
                isDivider={item.isDivider}
                badge={item.badge}
                sub={item.subMenu}
                sidebarToggle={sidebarToggle}
                mobileView={mobileView}
              /> : ''
          )
        )
      )}
    </ul>
  );
};

export const FloorMenu = ({ sidebarToggle, mobileView, isSignout }) => {
  return (
    <ul className="nk-menu nk-menu-md">
      {floorMenuItems.map((item) => {
        return (
          <MenuItem
            key={item.text}
            link={item.link}
            icon={item.icon}
            isSignout={item.isSignout}
            text={item.text}
            isDivider={item.isDivider}
            badge={item.badge}
            sub={item.subMenu}
            sidebarToggle={sidebarToggle}
            mobileView={mobileView}
          />
        );
      })
      }
    </ul>
  );
};

export default Menu;

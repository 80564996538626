import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Field } from "../../../../components/fouladyar/field/field";
import { deleteAndReturnRemaining } from "../../../../shared/textTools";
import { useTranslation } from "react-i18next";
import { LuSearch } from "react-icons/lu";
import {
  clearSearchOptions,
  setSearchOptions,
  setSelectedItem
} from "../../../../redux/store/services/search/store/search-actions";
import SafeAreaHeader from "../../components/safeAreaHeader";

import { StandaloneSearchBox } from "@react-google-maps/api";

const SearchForm = () => {

  const auth = useSelector((state) => state.auth);
  const search = useSelector((state) => state.search);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [selectedCity, setSelectedCity] = useState([]);
  const [selectedCityGeo, setSelectedCityGeo] = useState([]);
  const [data, setData] = useState({
    "searchBy": "name",
    "text": "",
    "specialization": "GENERAL_PRACTITIONER",
    "longitude": 2.3120211227789267,
    "latitude": 48.82477958916758,
    "paymentTypes": [
      "CHECK",
      "BANK_CARD",
      "SPECIES"
    ],
    "languages": [
      { value: "FRENCH", label: "Français" }
    ],
    "acceptVitalCard": false,
    "canDoPediatrics": true,
    "canDoAbortion": false,
    "canDoUltrasound": false
  });


  useEffect(() => {
    setData(search);
  }, []);

  useEffect(() => {
    console.log("search fields changed!", search);
  }, [search]);


  function handleOnClick() {
    dispatch(setSearchOptions({
      ...data,
      text: data.searchBy === "location" ? selectedCity : data.text
    }));


    if (selectedCity) {
      // const geo = { lat: selectedCity.lat, long: selectedCity.long };


      console.log("setSelectedItem", selectedCityGeo);
      if (selectedCityGeo) {
        dispatch(setSelectedItem(selectedCityGeo));
      }

    }
    navigate(`/home`);
  }

  const [searchBox, setSearchBox] = React.useState(null);

  const onPlacesChanged = React.useCallback(
    () => {
      const place = searchBox.getPlaces();

      let lat = place[0].geometry.location.lat();
      let lng = place[0].geometry.location.lng();

      const foundedCity = selectCity(place[0]);

      debugger;

      setSelectedCity(foundedCity ? foundedCity.short_name : place[0].address_components[1].long_name);
      setSelectedCityGeo({
        longitude: lng,
        latitude: lat
      });
    },
    [searchBox]
  );

  const onSearchBoxLoad = React.useCallback((ref) => {
    setSearchBox(ref);
  }, []);

  const selectCity = (place) => {

    const city = place.address_components.filter(f => f.types.filter(adr => adr == "administrative_area_level_2").length > 0);

    return city.length > 0 ? city[0] : null;
  };

  return (

    <>
      <SafeAreaHeader
        title={"Recherche"}
        // rightIcon={<IoClose size={24} color={"#555555"} />}
        // rightIconOnclick={()=>{
        //   navigate(`/home`);
        // }}
      />
      <div className="scroll-fixed-area">
        <div className="scroll-container">


          <div className="container">
            <div class="btn-group w-100 mb-0" role="group" aria-label="Basic example">
              <div className={`btn btn-group-fixed-width ${data.searchBy === "name" ? "blue-button" : "white-button"}`}
                   onClick={() => setData(prevState => ({
                     ...prevState,
                     searchBy: "name"
                   }))}>
                {t("by-name")}
              </div>

              <div
                className={`btn btn-group-fixed-width ${data.searchBy === "location" ? "blue-button" : "white-button"}`}
                onClick={() => setData(prevState => ({
                  ...prevState,
                  searchBy: "location"
                }))}>
                {t("by-location")}
              </div>
            </div>
          </div>

          <div className="container">
            <div className="search-box d-flex flex-row justify-content-between"
                 style={{ paddingRight: (data.searchBy === "location") ? "10px" : "0px" }}>

              <div className="w-auto d-flex btn">
                <LuSearch size={22} color={"#363636"} />
              </div>
              {
                data.searchBy === "location" ?
                  <>
                    <StandaloneSearchBox
                      onLoad={onSearchBoxLoad}
                      onPlacesChanged={onPlacesChanged}
                      options={
                        {
                          componentRestrictions: { country: ["FR"] }
                        }}>
                      <input
                        type="text"
                        placeholder=""
                        className={`form-control form-control-lg clean-input col`}
                      />
                    </StandaloneSearchBox>
                    {/* <Field
                      id={"search-text"}
                      name={"search-text"}
                      type={"autocomplete"}
                      className={`form-control form-control-lg clean-input col`}
                      options={cities}
                      inputValue={data.text}
                      onChange={(e) => {

                        setSelectedCity(e.top_city);
                        setSelectedCityGeo({
                          longitude: e.longitude,
                          latitude: e.latitude
                        });

                      }}
                      onInputChange={async (e) => {

                        setData(prevState => ({
                          ...prevState,
                          text: e
                        }));

                        if (data.text.length > 1) {
                          try {
                            const res = await axios.get(`${process.env.REACT_APP_SEARCH_API_URL}/find/cities?city=${e}`);
                            if (res.status === 200) {
                              setCities(res.data.map(i => {
                                // city: "Lyon 08"
                                // latitude: 45.7363
                                // longitude: 4.8687
                                // top_city: "Rhône"

                                return ({
                                  label: i.city,
                                  value: { top_city: i.top_city, longitude: i.longitude, latitude: i.latitude }
                                });
                              }));
                            }
                            // {label: 'Arabe', value: 'ARABIC'},
                          } catch (e) {
                            setCities([]);
                            ErrorToaster(e);
                          }
                        } else {
                          setCities([]);
                        }
                      }}
                    />*/}
                  </> :
                  <Field
                    id={"search-text"}
                    name={"search-text"}
                    type={"text"}
                    className={`form-control form-control-lg clean-input col`}

                    value={data.text}
                    onChange={(e) => {
                      setData(prevState => ({
                        ...prevState,
                        text: e
                      }));
                    }}
                  />
              }

            </div>
          </div>

          <div className="container">

            <Field
              id={"specialization"}
              name={"specialization"}
              label={t("specialization")}
              isRequired={true}
              type={"select"}
              options={[
                { label: t("Toute"), value: "ALL" },
                { label: t("GENERAL_PRACTITIONER"), value: "GENERAL_PRACTITIONER" },
                { label: t("GYNECOLOGY"), value: "GYNECOLOGY" },
                { label: t("PEDIATRICS"), value: "PEDIATRICS" },
                { label: t("DENTIST"), value: "DENTIST" },
                { label: t("RADIOLOGY"), value: "RADIOLOGY" },
                { label: t("CARDIOLOGY"), value: "CARDIOLOGY" },
                { label: t("GASTROENTEROLOGY"), value: "GASTROENTEROLOGY" },
                { label: t("ENDOCRINOLOGY_DIABETOLOGY"), value: "ENDOCRINOLOGY_DIABETOLOGY" }
              ]}
              value={data.specialization}
              onChange={(e) => {
                setData(prevState => ({
                  ...prevState,
                  specialization: e
                }));
              }}
            />
            <div className=" mt-2 mb-2 d-flex flex-row  justify-start align-center">
              <Field
                id={"canDoAbortion"}
                name={"canDoAbortion"}
                label={t("canDoAbortion")}
                type={"checkbox"}
                value={data.canDoAbortion}
                onChange={(e) => {
                  setData(prevState => ({
                    ...prevState,
                    canDoAbortion: !data.canDoAbortion
                  }));
                }}
              />
              <Field
                id={"canDoPediatrics"}
                name={"canDoPediatrics"}
                label={t("canDoPediatrics")}
                type={"checkbox"}
                value={data.canDoPediatrics}
                onChange={(e) => {
                  setData(prevState => ({
                    ...prevState,
                    canDoPediatrics: !data.canDoPediatrics
                  }));
                }}
              />
              <div style={{ marginBottom: "1.25rem" }}>
                <Field
                  id={"canDoUltrasound"}
                  name={"canDoUltrasound"}
                  label={t("canDoUltrasound")}
                  type={"checkbox"}
                  value={data.canDoUltrasound}
                  onChange={(e) => {
                    setData(prevState => ({
                      ...prevState,
                      canDoUltrasound: !data.canDoUltrasound
                    }));
                  }}
                />
              </div>
            </div>

            <div className="d-flex flex-row  justify-start align-center">
              <div className={`form-label-group bold`}>
                <label>
                  {t("payment")} :
                </label>
              </div>
            </div>
            <div className="d-flex flex-row mb-2 justify-start align-center">


              <Field
                id={"CHECK"}
                name={"CHECK"}
                label={t("CHECK")}
                type={"checkbox"}
                value={data.paymentTypes.includes("CHECK")}
                onChange={(e) => {
                  setData(prevState => ({
                    ...prevState,
                    paymentTypes: data.paymentTypes.includes("CHECK") ? deleteAndReturnRemaining(data.paymentTypes, "CHECK") : [...data.paymentTypes, "CHECK"]
                  }));
                }}
              />
              <Field
                id={"BANK_CARD"}
                name={"BANK_CARD"}
                label={t("BANK_CARD")}
                type={"checkbox"}
                value={data.paymentTypes.includes("BANK_CARD")}
                onChange={(e) => {
                  setData(prevState => ({
                    ...prevState,
                    paymentTypes: data.paymentTypes.includes("BANK_CARD") ? deleteAndReturnRemaining(data.paymentTypes, "BANK_CARD") : [...data.paymentTypes, "BANK_CARD"]
                  }));
                }}
              />
              <div style={{ marginBottom: "1.25rem" }}>
                <Field
                  className={""}
                  id={"SPECIES"}
                  name={"SPECIES"}
                  label={t("SPECIES")}
                  type={"checkbox"}
                  value={data.paymentTypes.includes("SPECIES")}
                  onChange={(e) => {
                    setData(prevState => ({
                      ...prevState,
                      paymentTypes: data.paymentTypes.includes("SPECIES") ? deleteAndReturnRemaining(data.paymentTypes, "SPECIES") : [...data.paymentTypes, "SPECIES"]
                    }));
                  }}
                />
              </div>
            </div>

            <div className="row mb-4 remove-cancel-selection">
              <Field
                id={"languages"}
                name={"languages"}
                label={t("selectLanguageTitleInPatient")}
                isRequired={true}
                type={"multiselect"}
                options={[
                  { label: "Français", value: "FRENCH", disabled: true },
                  { label: "Arabe", value: "ARABIC" },
                  { label: "Anglais", value: "ENGLISH" },
                  { label: "portugais", value: "PORTUGUESE" },
                  { label: "Espagnol", value: "SPANISH" },
                  { label: "Allemand", value: "GERMAN" },
                  { label: "Roumain", value: "ROMANIAN" },
                  { label: "Italien", value: "ITALIAN" },
                  { label: "Turc", value: "TURKISH" },
                  { label: "Persan", value: "PERSIAN" },
                  { label: "Chinese", value: "CHINESE" }
                ]}
                value={data.languages}
                onChange={(e) => {

                  if (e.length <= 2) {
                    setData(prevState => ({
                      ...prevState,
                      languages: e
                    }));
                  }

                }}
              />
            </div>


            {/* <div className="row mb-2 p-2">
          {
            data.languages.map(item=>{
              return(
                <div className="cancelable-data-badge d-flex flex-row mb-1">
                  <div>
                    {item.label}
                  </div>
                  <div onClick={()=>{
                    if(item.value !== "french")
                      setData(prevState => ({
                        ...prevState,
                        languages: data.languages.filter(i=> i.value !== item.value)
                      }))
                  }}>
                    <MdClose  size={18} color={item.value === "french" ? "#a8a7a7" : "#555555"} />
                  </div>
                </div>
              )
            })
          }
        </div>*/}


            {/*<div className="d-flex flex-row  justify-start align-center">*/}
            {/*  <div className={`form-label-group bold`}>*/}
            {/*    <label >*/}
            {/*      {t('acceptVitalCard')} :*/}
            {/*    </label>*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className="row mb-3 ">
              <Field
                id={"acceptVitalCard"}
                name={"acceptVitalCard"}
                label={t("acceptVitalCard")}
                isRequired={true}
                type={"checkbox"}
                options={[
                  { label: "Oui", value: true },
                  { label: "Non", value: false }
                ]}
                value={data.acceptVitalCard}
                onChange={(e) => {
                  setData(prevState => ({
                    ...prevState,
                    acceptVitalCard: e
                  }));
                }}
              />
            </div>

            <div className={`btn blue-button`} onClick={handleOnClick}>
              {t("search")}
            </div>

            <div className={`btn white-button mb-3`} onClick={() => {
              dispatch(clearSearchOptions());
              navigate(`/home`);
            }}>
              {t("clear-search")}
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default SearchForm;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Icon, UserAvatar } from "../../../../components/Component";
import ModalHelper from "../../../../components/fouladyar/modal-helper/modalHelper";
import HeaderDoctor from "../../../../layout/header/Header-doctor";
import { Bar } from "react-chartjs-2";
import { convertToTitleCase } from "../../../../shared/textTools";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { getIndexOfWeek } from "../../../../shared/shared";
import { Field } from "../../../../components/fouladyar/field/field";
import moment from "moment";
import { EmptyState } from "../../../../components/fouladyar/empty-state/emptyState";
import EmptyStateImage from "../../../../assets/images/cliconsult/empty-states/home-empty-state.png";
import User from "../../../../images/avatar/b-sm.jpg";
import toast from "react-hot-toast";

const DoctorHome = () => {
  // const localizer = momentLocalizer(moment);
  const auth = useSelector((state) => state.auth);
  const profile = useSelector((state) => state.profile);
  const navigate = useNavigate();

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const { t, i18n } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalComponent, setModalComponent] = useState(<div>empty</div>);
  const [selectedChart, setSelectedChart] = useState('week');
  const [todaysSessions, setTodaysSessions] = useState([]);
  const [todaysStatistics, setTodaysStatistics] = useState({
    "waiting":0,
    "visited":0,
    "cancelled":0
  });
  const [selectedSession, setSelectedSession] = useState();

  async function getAllSessions() {
    try {


      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/session/${moment(new Date()).format("YYYY-MM-DD")}`, {
        headers: { authorization: `bearer ${auth.token}` }
      });
      console.log("sessions", res);
      if (res.status === 200) {
        setTodaysSessions(res.data);
      }

    } catch (e) {
      console.log("error", e);
      setTodaysSessions([]);
    }
  }
  async function getTodaysStatistics() {
    try {

      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/session/today/statistics`, {
        headers: { authorization: `bearer ${auth.token}` }
      });
      console.log("statistics", res);
      if (res.status === 200) {
        setTodaysStatistics(res.data);
      }

    } catch (e) {
      console.log("error", e);
      setTodaysStatistics([]);
    }
  }


  function SessionSummary(){
    return(
      <div className="d-flex flex-row dashboard-summary justify-center align-center mt-4">
        <div className="container mh-130 d-flex flex-column justify-center align-center" >


          <div className="col-sm pb-1 pt-1 p-0">
            <div className="container mh-130 d-flex flex-column justify-center align-center">
              <div className="row dashboard-summary-row dashboard-summary-thin"
                   style={{ fontWeight: "500" }}>Les horaires du cabinet
                aujourd’hui :
              </div>
              <div className="row dashboard-summary-row dashboard-summary-current-session">
                <Field
                  id={"session"}
                  name={"session"}
                  type={"select"}
                  placeholder={"sélectionner"}
                  options={todaysSessions.length > 0 ? todaysSessions.map(i => {
                    return { label: `${i.start} - ${i.end}`, value: i.id };
                  }) : []}
                  value={selectedSession}
                  onChange={(e) => {
                    setSelectedSession(e);
                  }}
                />
              </div>
              <div className="pb-4"></div>
              <div className="row dashboard-summary-row dashboard-summary-thin"
                   style={{ fontWeight: "500" }}>Durée des séances :
              </div>
              <div
                className="row dashboard-summary-row dashboard-summary-bold">{todaysSessions.find(i => i.id === selectedSession)?.length || 0} minutes
              </div>
            </div>
          </div>
          <div className="d-none d-sm-block">
            <Link to={`${process.env.PUBLIC_URL}/calendar`}>
              <div className={`btn btn-sm white-button mt-1 mb-3 w-auto`} style={{fontSize: "14px !important"}} onClick={() => {}}>
                Voir le calendrier
              </div>
            </Link>
          </div>
        </div>
      </div>
    )
  }

  function HomeDataItem({title, value, className}){
    return(
      <div className="col-4 d-flex justify-content-center">
        <div className={`home-data-item row ${className}`}>
          <div className="col-sm-12 col-md-4 number d-flex justify-center align-center">
            {value}
          </div>
          <div className="col-sm-12 col-md-8 text">
            <span style={{fontSize: screenWidth < 600 ? "13px" : "16px"}}>{title}</span>
          </div>
        </div>
      </div>
    )
  }


  function MiniProfile({}){
    return(
      <div className={`d-none d-sm-block home-data-item p-4 d-flex flex-column h-100 justify-between`}>

        <div>
          <div className="d-flex flex-row  justify-center align-center">
            <div style={{ paddingLeft: 0, width: "auto" }}>
              <UserAvatar className="profile-avatar-big" image={profile.avatar ? `${process.env.REACT_APP_S3_BUCKET}/${profile.avatar}` : User}></UserAvatar>
            </div>
          </div>
          <div className="pt-3 home-profile-username text-center"> {`
            ${profile.gender === "MALE" ? "M." : (profile.gender === "FEMALE" ? "Mme" : "Inconnu")} 
            ${profile.doctorOrProfessor === "DOCTOR" ? 'Dr.' : (profile.doctorOrProfessor === "PROFESSOR" ? "Prof." : "")}
            ${convertToTitleCase(profile.firstName)} ${convertToTitleCase(profile.lastName)}`}
          </div>
          <div className="pt-1 home-profile-username text-center" style={{fontSize: "20px", fontWeight: "400"}}>
            Passez une bonne journée
          </div>
        </div>


        <SessionSummary/>

      </div>
    )
  }
  const daysObject = {
    "sunday": "SUNDAY",
    "monday": "MONDAY",
    "tuesday": "TUESDAY",
    "wednesday": "WEDNESDAY",
    "thursday": "THURSDAY",
    "friday": "FRIDAY",
    "saturday": "SATURDAY"
  };
  const week = {
    labels: ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"],
    datasets: [
      {
        label: "visited",
        // data: [26, 36, 16, 30, 10, 0, 0],
        data: [0, 0, 0, 0, 0, 0, 0],
        backgroundColor: "#1AA0B4",
        barThickness: 30
      },
      {
        label: "cancelled",
        // data: [8, 3, 6, 8, 2, 0, 0],
        data: [0, 0, 0, 0, 0, 0, 0],
        backgroundColor: "#016FC4",
        barThickness: 30
      },
      {
        label: "cancelledByProvider",
        // data: [10, 20, 3, 10, 1, 0, 0],
        data: [0, 0, 0, 0, 0, 0, 0],
        backgroundColor: "#00589C",
        barThickness: 30
      }
    ]
  };

  const [chart, setChart] = useState(week);
  const options = {

    plugins: {
      title: {
        display: true,
        text: "Chart.js Bar Chart - Stacked"
      }
    },
    aspectRatio: screenWidth < 600 ? 1 : 1.7,
    responsive: true,
    scales: {
      x: {
        stacked: true
      },
      y: {
        stacked: true
      }
    }
  };


  async function getChartData({props}){
    try {


        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/statistics/week/${props.year}/${props.indexOfWeek}`,{
          headers: {authorization: `bearer ${auth.token}`}
        });
        console.log("week", res);
        if (res.status === 200) {

          setChart(week);
          setChart(prevState => ({
            labels: prevState.labels.map(i=> {
              return t(i);
            }),
            datasets: prevState.datasets.map(dataset => {
              return {
                ...dataset,
                label: t(dataset.label),
                data: prevState.labels.map(day => {
                  const dataFound = res.data.find(item => item.dayOfWeek === daysObject[day]);

                  if(dataFound)
                    return dataFound[dataset.label]

                  return 0;
                })
              }
            })
          }))

        }


    } catch (e) {
      console.log("error", e);
      toast.error(e.message)
    }
  }

  useEffect(()=>{
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const weekNumber = getIndexOfWeek()
    let props = selectedChart === 'week' ?
      {year: currentYear, indexOfWeek: weekNumber} : (
        selectedChart === 'month' ? {year: currentYear, nameOfMonth: getCurrentMonthName()} :
          {year: currentYear}
      )

    console.log('weekNumber', weekNumber)
    getChartData({
      type: selectedChart,
      props: props
    })


    getAllSessions();
    getTodaysStatistics();

    // Function to update screenWidth when the window is resized
    const updateScreenWidth = () => {
      setScreenWidth(window.innerWidth);
    };
    const updateScreenHeight = () => {

      setScreenHeight(window.innerHeight);
    };


    // Add event listener for window resize
    window.addEventListener('resize', updateScreenWidth);
    window.addEventListener('resize', updateScreenHeight);
    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', updateScreenWidth);
      window.removeEventListener('resize', updateScreenHeight);
    };
  }, [])




  return (

    <React.Fragment>

      <ModalHelper
        size={"sm"}
        open={isModalOpen}
        onOpen={() => setIsModalOpen(true)}
        onClose={() => setIsModalOpen(false)}
        component={modalComponent}
      />

      <HeaderDoctor title={"Accueil"} />
      <div className="nk-content p-2">
        <div className="container p-0 m-0 nk-block-card">

          <div className="row">
            <div className="d-block d-sm-none mb-3">
              <SessionSummary/>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-8 mb-2">
              <div className="g-3 d-flex flex-row justify-center align-center">
                <HomeDataItem value={ todaysStatistics.waiting || 0} title={ t("WAITING")} className={''}/>
                <HomeDataItem value={ todaysStatistics.visited || 0} title={ t("VISITED")} className={''}/>
                <HomeDataItem value={ todaysStatistics.cancelled || 0} title={ t("CANCELED")} className={''}/>
              </div>
              <div className="p-3 mt-3 home-data-item home-bar" style={{minHeight: "70vh"}}>
                {
                  todaysSessions.length === 0 ?
                    <>
                      <EmptyState
                        title={"Bienvenue au CLICONSULT"}
                        content={"Félicitations pour avoir fait le premier pas dans une gestion transparente de vos rendez-vous. Votre centre de rdv est là pour simplifier votre planification et garantir que vous restiez concentré sur ce qui compte le plus."}
                        image={EmptyStateImage}
                      />
                      <div className="mt-3 mb-3 d-flex flex-wrap justify-content-center">
                        <Link to={`${process.env.PUBLIC_URL}/calendar`}>
                          <div className={`btn blue-button`}>
                            Allez sur votre agenda
                          </div>
                        </Link>
                      </div>
                    </> :
                    <Bar data={chart} options={options}   />
                }
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4 mb-2">
              <MiniProfile/>
            </div>
          </div>






        </div>
      </div>
    </React.Fragment>

  );
};

export default DoctorHome;

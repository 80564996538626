import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import ModalHelper from "../../../../components/fouladyar/modal-helper/modalHelper";
import HeaderDoctor from "../../../../layout/header/Header-doctor";
import { Field } from "../../../../components/fouladyar/field/field";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { ErrorToaster } from "../../../../shared/toaster";
import toast from "react-hot-toast";

const DoctorChangePassword = () => {
  const auth = useSelector((state) => state.auth);
  const profile = useSelector((state) => state.profile);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalComponent, setModalComponent] = useState(<div>empty</div>);


  return (
    <React.Fragment>
      <ModalHelper
        size={"sm"}
        open={isModalOpen}
        onOpen={() => setIsModalOpen(true)}
        onClose={() => setIsModalOpen(false)}
        component={modalComponent}
      />

      <HeaderDoctor title={"Contacter le support"} />
      <div className="nk-content ">
        <div className="container-fluid-doctor">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block">
                <div className="container">
                  <div className="row ps-4 pe-4">
                    <div className="col-sm-12 col-md-12 col-lg-12 mt-5 mb-5 p-5 profile-container">
                      <div className="d-flex justify-content-center">
                        <div className="col-sm-12 col-md-6 col-xxl-6">
                          <ContactSupportComponent />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>

  );
};


export const ContactSupportComponent = () => {

  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [data, setData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmedPassword: ""
  });

  async function onSubmit() {
    try {

      if (data.newPassword !== data.confirmedPassword) {
        toast.error(`Les mots de passe saisis ne sont pas les mêmes. Veuillez vérifier à nouveau.`);
      }

      const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/change-password`, data, {
        headers: { authorization: `bearer ${auth.token}` }
      });

      if (res.status === 200) {
        toast.success(`Votre mot de passe a été changé avec succès`);
        navigate(`/profile`);
      }

    } catch (e) {
      ErrorToaster(e);
    }
  }

  return(
    <div>
      <div className="">
        <Field
          id={"old-password"}
          name={"old-password"}
          label={"Votre mot de passe actuel"}
          type={"password"}
          value={data.oldPassword}
          onChange={(e) => {
            setData(prevState => ({
              ...prevState,
              oldPassword: e
            }));
          }}
        />
      </div>
      <div className="">
        <Field
          id={"password"}
          name={"password"}
          label={"Nouveau mot de passe"}
          type={"password"}
          value={data.newPassword}
          onChange={(e) => {
            setData(prevState => ({
              ...prevState,
              newPassword: e
            }));
          }}
        />
      </div>
      <div className="">
        <Field
          id={"confirm-password"}
          name={"confirm-password"}
          label={"Confirmation du nouveau mot de passe"}
          type={"password"}
          value={data.confirmedPassword}
          onChange={(e) => {
            setData(prevState => ({
              ...prevState,
              confirmedPassword: e
            }));
          }}
        />
      </div>

      <div className="mt-3 mb-3">
        <div className="col-sm-12">
          <div className={`btn blue-button`} onClick={async () => {
              await onSubmit();
          }}>
            Changer le mot de passe
          </div>
        </div>
      </div>
    </div>
  )
}
export default DoctorChangePassword;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import ModalHelper from "../../../../components/fouladyar/modal-helper/modalHelper";
import HeaderDoctor from "../../../../layout/header/Header-doctor";
import { Field } from "../../../../components/fouladyar/field/field";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { ErrorToaster } from "../../../../shared/toaster";
import toast from "react-hot-toast";
import { clearToken } from "../../../../redux/store/services/auth/store";
import { clearProfile } from "../../../../redux/store/services/profile/store/profile-actions";

const DeleteAccountPateint = () => {
  const auth = useSelector((state) => state.auth);
  const profile = useSelector((state) => state.profile);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalComponent, setModalComponent] = useState(<div>empty</div>);

  const [data, setData] = useState({
    "password": "",
  })


  async function onDeleteAccount() {

    try {

      const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/provider/account`, {
        headers: { "authorization": `bearer ${auth.token}` },
        data: {password: data.password}
      });

      if (res.status === 200) {
        toast.success('votre thème a été mis à jour avec succès')
        localStorage.removeItem("accessToken");
        dispatch(clearToken())
        dispatch(clearProfile())
        navigate(`/login`);
      }

    } catch (e) {
      ErrorToaster(e, t);
    }
  }


  return (
    <React.Fragment>
      <ModalHelper
        size={"sm"}
        open={isModalOpen}
        onOpen={() => setIsModalOpen(true)}
        onClose={() => setIsModalOpen(false)}
        component={modalComponent}
      />

      <HeaderDoctor title={"Contacter le support"} />
      <div className="nk-content ">
        <div className="container-fluid-doctor">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block">
                <div className="container">
                  <div className="row ps-4 pe-4">
                    <div className="col-sm-12 col-md-12 col-lg-12 mt-5 mb-5 p-5 profile-container">
                      <div className="d-flex justify-content-center">
                        <div className="col-sm-12 col-md-6 col-xxl-6">
                          <div className="container" >
                            <div className="row d-flex mt-4">
                              <h6 className="profile-title p-2">{"Supprimer mon compte et mes \n" +
                                "données"}</h6>
                            </div>

                            <div className="row mb-4">
                              <p className="">
                                Confirmez votre demande de suppression de compte
                              </p>
                            </div>


                            <div className="row mb-5">
                              <Field
                                id={"password"}
                                name={"password"}
                                label={"Entrez le mot de passe pour confirmer"}
                                type={"password"}
                                value={data.password}
                                onChange={(e) => { setData(prevState => ({
                                  ...prevState,
                                  password: e
                                }))}}
                              />
                            </div>


                          </div>
                          <div className="container mb-4 bt-4">
                            <div className={`btn blue-button mb-2 ${!data.password ? "disabled" : ""}`} onClick={async () => {
                              if(data.password)
                                await onDeleteAccount()
                            }}>
                              Supprimer
                            </div>


                            <div className={`btn white-button`} onClick={() => {
                              navigate('/profile')
                            }}>
                              Retour
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>

  );
};



export default DeleteAccountPateint;

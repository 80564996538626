import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Field } from "../../../../components/fouladyar/field/field";
import SafeAreaHeader from "../../components/safeAreaHeader";
import { CgClose } from "react-icons/cg";
import axios from "axios";
import { ErrorToaster } from "../../../../shared/toaster";
import toast from "react-hot-toast";

const ChangePassword = () => {
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [data, setData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmedPassword: ""
  });

  async function onSubmit() {
    try {

      if (data.newPassword !== data.confirmedPassword) {
        toast.error(`Les mots de passe saisis ne sont pas les mêmes. Veuillez vérifier à nouveau.`);
      }

      const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/change-password`, data, {
        headers: { authorization: `bearer ${auth.token}` }
      });

      if (res.status === 200) {
        toast.success(`Votre mot de passe a été changé avec succès`);
        navigate(`/my-profile`);
      }

    } catch (e) {
      ErrorToaster(e);
    }
  }


  return (
    <>
      <SafeAreaHeader
        rightIcon={<CgClose size={24} color={"#060606"} />}
        rightIconOnclick={() => {
          navigate(-1);
        }}
      />

      <div className="scroll-fixed-area">
        <div className="scroll-container">
          <div className="container">

            <div className="row d-flex">
              <h6 className="profile-title p-1 mb-5 mt-5 text-center">{"Changer le mot de passe"}</h6>
            </div>

            <div className="row  mb-4">
              <p className="text-center">
                Vous pouvez être déconnecté de votre compte sur certains appareils pour protéger votre compte.
              </p>
            </div>


            <div className="row mb-2">
              <Field
                id={"old-password"}
                name={"old-password"}
                label={"Votre mot de passe actuel"}
                type={"password"}
                value={data.oldPassword}
                onChange={(e) => {
                  setData(prevState => ({
                    ...prevState,
                    oldPassword: e
                  }));
                }}
              />
            </div>

            <div className="row mb-2">
              <Field
                id={"password"}
                name={"password"}
                label={"Nouveau mot de passe"}
                type={"password"}
                value={data.newPassword}
                onChange={(e) => {
                  setData(prevState => ({
                    ...prevState,
                    newPassword: e
                  }));
                }}
              />
            </div>

            <div className="row mb-2">
              <Field
                id={"confirm-password"}
                name={"confirm-password"}
                label={"Confirmation du nouveau mot de passe"}
                type={"password"}
                value={data.confirmedPassword}
                onChange={(e) => {
                  setData(prevState => ({
                    ...prevState,
                    confirmedPassword: e
                  }));
                }}
              />
            </div>


          </div>
          <div className="container mb-4">
            <div className={`btn blue-button`} onClick={async () => {
              await onSubmit();
            }}>
              Changer le mot de passe
            </div>
          </div>

          <div className="row d-flex justify-center">
            <Link to={`/forget-password`} className="logo-link" style={{ textAlign: "center" }}>
              j'ai oublié mon mot de passe
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;

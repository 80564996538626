import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Sidebar from "./sidebar/Sidebar";
import Head from "./head/Head";
import AppRoot from "./global/AppRoot";
import AppMain from "./global/AppMain";
import AppWrap from "./global/AppWrap";
import UserMap from "../components/partials/default/user-map/UserMap";
import { useSelector } from "react-redux";
import { validateToken } from "../pages/auth";
import { useTranslation } from "react-i18next";
import { LuCalendar, LuHome } from "react-icons/lu";
import { IoSearch } from "react-icons/io5";
import { TbTicket } from "react-icons/tb";
import { FiUser, FiUserCheck } from "react-icons/fi";
import { NavItem } from "./buttomNavBar/ButtomNavBar";

const DoctorLayout = ({ title, app, ...props }) => {


  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [IS_TOKEN_VALID, SET_IS_TOKEN_VALID] = useState(false);



  useEffect(() => {
    async function validateAccessToken(){
      const tokenValidation = await validateToken(auth.token);


      if(!tokenValidation){
        navigate(`/login`)
      }else{
        SET_IS_TOKEN_VALID(true);
      }
    }
    validateAccessToken()


  }, [auth.token]);

  return (
    <>
      {
        !IS_TOKEN_VALID ? <></> :
        <>
          <Head title={!title && "Loading"} />
          <AppRoot >
            <AppMain>
              <Sidebar fixed />
              <AppWrap>
                <Outlet />
              </AppWrap>
              <BottomNavBar/>
            </AppMain>
          </AppRoot>
        </>
      }

    </>
  );
};
export default DoctorLayout;
const BottomNavBar = ({  ...props }) => {

  const { t, i18n } = useTranslation();


  return(
    <div className="bottom-nav-bar">
      <NavItem link={'/home'} icon={<LuHome size={22} color={"#060606"}/>} text={t('Accueil')}/>
      <NavItem link={'/patients'} icon={<FiUserCheck   size={22} color={"#060606"}/>} text={t('Patients')}/>
      <NavItem link={'/calendar'} icon={<LuCalendar  size={22} color={"#060606"}/>} text={t('Calendrier')}/>
    </div>
  )
}

export const DoctorBaseOutlet = ({ title, app, ...props }) => {
  return (
    <div className="doctor-layout">

    </div>
  );
};

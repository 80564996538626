import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { UserAvatar } from "../../../../components/Component";
import Logo from "../../../../assets/images/cliconsult/headerLogo.png";
import axios from "axios";
import { useTranslation } from "react-i18next";
import SafeAreaHeader from "../../components/safeAreaHeader";
import { BiChevronLeft } from "react-icons/bi";
import { Field } from "../../../../components/fouladyar/field/field";
import { LuMinus } from "react-icons/lu";
import { MdAdd } from "react-icons/md";
import toast from "react-hot-toast";
import { clearTickets, setActiveTickets } from "../../../../redux/store/services/ticket/store/ticket-actions";
import { validateToken } from "../../../auth";
import { ErrorToaster } from "../../../../shared/toaster";

const DoctorTakeAppointmentForm = () => {

  const auth = useSelector((state) => state.auth);
  const ticket = useSelector((state) => state.ticket);
  const mode = useSelector((state) => state.search.mode);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false)
  const [profile, setProfile] = useState({})
  const [agreement, setAgreement] = useState(false)
  const [data, setData] = useState({
    "providerSubjectId": "",
    "firstName": "",
    "lastName": "",
    "dateOfBirth": "",
    "mobileNumber": "",
    "numOfPersons": 1,
    "providerType": mode.toUpperCase() || "DOCTOR"
  })
  const [theme, setTheme] = useState({
    themeBackgroundColor: '',
    themeButtonColor: '',
    themeTextColor: '',
    themeNavbarColor: '',
    logo: ''
  });
  const { id } = useParams();
  const [nextAppointmentTime, setNextAppointmentTime] = useState("")

  useEffect(() => {
    if(!state){
      toast.error('session expired')
      navigate('/history')
    }

    async function loadAvailableSession() {
      try {

        const nextAppointmentTimeResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/provider/appointment/next/${id}`, {
        });

        if (nextAppointmentTimeResponse.status === 200 ) {
          setNextAppointmentTime(nextAppointmentTimeResponse.data.time)
          setIsLoading(false);
        }

        const themeResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/provider/account/${id}`, {
          headers: { "authorization": `bearer ${auth.token}` }
        });

        if (themeResponse.status === 200) {
          setTheme(themeResponse.data);
        }


      } catch (e) {
        console.error("Error in find/providers", e);
        setIsLoading(false);
        toast.error(e.response.data.error || (e.response.data.message || e.message))
      }
    }



    async function loadProfile(){
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/client/account`, {
          headers: { "authorization": `bearer ${auth.token}` }
        });

        if (res.status === 200) {
          setData(prevState =>({
            ...prevState,
            ...res.data
          }))
        }

      }catch (e){
        toast.error(e.response.data.error || (e.response.data.message || e.message))
      }
    }

    loadAvailableSession();

    if(validateToken(auth.token)){
      loadProfile()
    }
  }, []);

  async function onSubmitForm() {
    try {

      if((ticket.active.filter(item => item.providerType === mode.toUpperCase())).length > 0){
        toast.error(`Vous avez déjà un rendez-vous , Pour prendre un nouveau rendez-vous, veuillez d'abord annuler votre rendez-vous actuel ! `)
        return;
      }

      setIsLoading(true)
      const _data = {...data}
      delete _data.email;
      delete _data.image;
      delete _data.subjectId;

      const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/client/appointment`, {
        ..._data,
        providerSubjectId: id
      },{
        headers: { "authorization": `bearer ${auth.token}` }
      });

      if (res.status === 200 ) {
        // age: 0
        // appointmentId: "519578806836606441"
        // clientSubjectId: ""
        // fullName: "rwerqw eeeee"
        // mobileNumber: "09036958471"
        // numOfPersons: 2
        // sessionId: "519561036375937400"
        // status: "WAITING"
        // turnNumber: 1
        // turnsToAwait: 0
        // visitTime: "19:20"

        dispatch(setActiveTickets(
          {
            "clientSubjectId": "",
            "providerSubjectId": id,
            "providerFullName": res.data.providerFullName,
            "appointmentDate": res.data.appointmentDate,
            "appointmentId": res.data.appointmentId,
            "sessionId": res.data.sessionId,
            "visitTime": res.data.visitTime,
            "clientFullName": res.data.fullName,
            "specialization": res.data.providerSpecialization,
            "providerType": mode.toUpperCase(),
            "numOfPersons": res.data.numOfPersons,
            "providerGender": res.data.providerGender
          }
        ))
        navigate(`/ticket/${id}/${res.data.appointmentId}`,{
          state:{
            themeBackgroundColor: state?.themeBackgroundColor || '',
            themeButtonColor: state?.themeButtonColor || '',
            themeTextColor: state?.themeTextColor || '',
            themeNavbarColor: state?.themeNavbarColor || '',
            icon: state?.icon || '',
          }
        });
      }

    } catch (e) {
      setIsLoading(false)
      ErrorToaster(e, t)
    }
  }

  return (
    <>

      <SafeAreaHeader
        styles={{background: state?.themeBackgroundColor}}
        leftIcon={<BiChevronLeft size={28} color={"#555555"} />}
        leftIconOnclick={() => {
          navigate(-1);
        }}
      />

      <div className="scroll-fixed-area" style={{paddingBottom: "unset"}}>
        <div className="scroll-container large-padding-bottom" style={{background: state?.themeBackgroundColor, paddingBottom: "1rem"}}>
          <div className="container">
            <div className="row  d-flex flex-row  justify-center align-center">
              <div className="col-sm-auto user-avatar-flex" style={{paddingLeft: 0}}>
                <UserAvatar className="logo-avatar" image={state?.logo ?`${process.env.REACT_APP_S3_BUCKET}/${state?.logo}` : (theme?.logo ? `${process.env.REACT_APP_S3_BUCKET}/${theme?.logo}` : Logo)  }></UserAvatar>
              </div>
            </div>

            <p className={` text-center pt-2 pb-2`} style={{fontSize: 17, color: state?.themeTextColor}}>Cliquez sur commencer pour prendre un RDV d'urgence</p>


            <div>
              <div className="row mb-2">
                <Field
                  id={"firstName"}
                  name={"firstName"}
                  label={t('Prénom')}
                  type={"text"}
                  value={data.firstName }
                  onChange={(e) => {
                    setData(prevState => ({
                      ...prevState,
                      firstName: e
                    }))
                  }}
                />
              </div>

              <div className="row mb-2">
                <Field
                  id={"lastName"}
                  name={"lastName"}
                  label={t('Nom')}
                  type={"text"}
                  value={data.lastName }
                  onChange={(e) => {
                    setData(prevState => ({
                      ...prevState,
                      lastName: e
                    }))
                  }}
                />
              </div>

              <div className="row mb-2">
                <Field
                  id={"dateOfBirth"}
                  name={"dateOfBirth"}
                  label={t('Date de naissance')}
                  type={"date"}
                  format={'dd/MM/yyyy'}
                  value={data.dateOfBirth }
                  onChange={(e) => {
                    setData(prevState => ({
                      ...prevState,
                      dateOfBirth: e
                    }))
                  }}
                />
              </div>

              <div className="row mb-2">
                <Field
                  id={"mobileNumber"}
                  name={"mobileNumber"}
                  label={t('Téléphone portable')}
                  type={"mobile-number"}
                  value={data.mobileNumber }
                  onChange={(e) => {
                    if(e.length <= 10)
                      setData(prevState => ({
                        ...prevState,
                        mobileNumber: e
                      }))
                  }}
                />
              </div>


              <div className=" ps-5 pe-5 mb-2  d-flex justify-content-center" >

                <div className="d-flex flex-row justify-content-between ps-5 pe-5 mb-2 " style={{minWidth: "270px"}}>
                  <div className="w-auto ps-0 pe-1">
                    <div className={`btn blue-button ticket-btn`} onClick={() => {
                      setData(prevState => ({
                        ...prevState,
                        numOfPersons: data.numOfPersons === 1 ? 1 : data.numOfPersons - 1
                      }))
                    }}>
                      <LuMinus size={19} color={"#fff"} />
                    </div>
                  </div>
                  <div className="w-auto pe-0 ps-0">
                    <Field
                      style={{textAlign: "center"}}
                      id={"numOfPersons"}
                      name={"numOfPersons"}
                      type={"number"}
                      value={data.numOfPersons}
                      onChange={(e) => {
                      }}
                    />
                  </div>
                  <div className="w-auto ps-1 pe-0">
                    <div className={`btn blue-button ticket-btn`} onClick={() => {
                      setData(prevState => ({
                        ...prevState,
                        numOfPersons: data.numOfPersons <= 8 ? data.numOfPersons + 1 : data.numOfPersons
                      }))
                    }}>
                      <MdAdd  size={19} color={"#fff"} />
                    </div>
                  </div>
                </div>
              </div>



              <div className="row mb-4">
                <Field
                  id={"agreement"}
                  name={"agreement"}
                  label={"J'accepte la politique de confidentialité et les conditions d'utilisation de CLICONSULT pour traiter mes données."}
                  type={"checkbox"}
                  value={agreement }
                  onChange={(e) => {
                    setAgreement(!agreement)
                  }}
                />
              </div>

              <div className={`btn ${state.themeButtonColor ? 'button-no-color' : 'blue-button'} ${!agreement ? 'disabled' : ''}`} style={{background: state.themeButtonColor }}  onClick={async () => {
                if(agreement) {
                  await onSubmitForm()
                }
              }}>
                soumettre <div className={"seyedi_green"}>{nextAppointmentTime}</div>
              </div>
            </div>
          </div>


        </div>
      </div>

    </>
  );
};

export default DoctorTakeAppointmentForm;

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "../../../../components/Component";
import { Field } from "../../../../components/fouladyar/field/field";
import toast from "react-hot-toast";
import axios from "axios";
import { ErrorToaster } from "../../../../shared/toaster";
import SafeAreaHeader from "../../../patient/components/safeAreaHeader";
import { BiChevronLeft } from "react-icons/bi";


const DoctorResetPassword = () => {
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const currentUrl = new URL(window.location.href);
  const [data, setData] = useState({
    token: currentUrl.searchParams.get('token'),
    newPassword: '',
    confirmedPassword: '',
  })

  async function onSubmit(){
    try {

      if(data.newPassword !== data.confirmedPassword){
        toast.error(  `Les mots de passe saisis ne sont pas les mêmes. Veuillez vérifier à nouveau.`)
      }

      const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/forget-password/reset`, data);

      if (res.status === 200) {
        toast.success(  `Votre mot de passe a été changé avec succès`)
        navigate(`/login`);
      }

    }catch (e){
      ErrorToaster(e)
    }
  }







  return (
    <div className="nk-content-auth pt-5">
      <div className="container-fluid-doctor">
        <div className="nk-content-inner">
          <div className="nk-content-body">
            <div className="nk-block d-flex justify-center align-center">
              <div className="container ">
                <div className="ps-3 pe-3">
                  <SafeAreaHeader
                    leftIcon={<BiChevronLeft size={28} color={"#555555"} />}
                    leftIconOnclick={()=>{
                      navigate(-1);
                    }}
                  />



                  <div className="container">


                    <div className="row d-flex  mt-5">
                      <h6 className="profile-title p-1 text-center" >{'Changer le mot de passe'}</h6>
                    </div>

                    {/*<div className="row mb-4">*/}
                    {/*  <p className="text-center">*/}
                    {/*    Veuillez saisir votre E-mail de connexion afin de recevoir*/}
                    {/*    <b> le lien de réinitialisation de votre mot de passe.</b>*/}
                    {/*  </p>*/}
                    {/*</div>*/}


                    <div className="row mb-2">
                      <Field
                        id={"password"}
                        name={"password"}
                        label={"Nouveau mot de passe"}
                        type={"password"}
                        value={data.newPassword}
                        onChange={(e) => {
                          setData(prevState => ({
                            ...prevState,
                            newPassword: e
                          }))
                        }}
                      />
                    </div>


                    <div className="row mb-2">
                      <Field
                        id={"confirm-password"}
                        name={"confirm-password"}
                        label={"Confirmation du nouveau mot de passe"}
                        type={"password"}
                        value={data.confirmedPassword}
                        onChange={(e) => {
                          setData(prevState => ({
                            ...prevState,
                            confirmedPassword: e
                          }))
                        }}
                      />
                    </div>

                  </div>

                  <div className="container mb-4">
                    <div className={`btn blue-button`} onClick={async () => {
                      await onSubmit()
                    }}>
                      Enregistrer un nouveau mot de passe
                    </div>
                  </div>

                  <div className="row d-flex">
                    <div className="p-1 text-center" >Si vous avez besoin d'assistance</div>
                  </div>

                  <div className="row d-flex justify-center">
                    <Link to={`${process.env.PUBLIC_URL}/support`}>
                      <div  className="col-sm-auto pt-0 pt-0 p-1 m-0  d-flex justify-center" >veuillez nous contacter</div>
                    </Link>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorResetPassword;

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "../../../../components/Component";
import { Field } from "../../../../components/fouladyar/field/field";
import SafeAreaHeader from "../../components/safeAreaHeader";
import { CgClose } from "react-icons/cg";
import axios from "axios";
import { ErrorToaster } from "../../../../shared/toaster";
import toast from "react-hot-toast";
import LogoLight2x from "../../../../images/logo2x.png";
import LogoDark2x from "../../../../images/logo-dark2x.png";

const ResetPassword = () => {
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const currentUrl = new URL(window.location.href);
  const [data, setData] = useState({
    token: currentUrl.searchParams.get('token'),
    newPassword: '',
    confirmedPassword: '',
  })

  async function onSubmit(){
    try {

      if(data.newPassword !== data.confirmedPassword){
        toast.error(  `Les mots de passe saisis ne sont pas les mêmes. Veuillez vérifier à nouveau.`)
      }

      const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/forget-password/reset`, data);

      if (res.status === 200) {
        toast.success(  `Votre mot de passe a été changé avec succès`)
        navigate(`/login`);
      }

    }catch (e){
      ErrorToaster(e)
    }
  }



  function Header() {
    return (
      <>
        <div className="container">
          <div className="row d-flex justify-end align-end p-0 pb-2 pt-2">
            <Link to={`${process.env.PUBLIC_URL}/login`} className="header-icon w-auto p-0 m-0">
              <Icon name="cross" className="p-0 text-end" style={{ fontSize: 24 }}></Icon>
            </Link>
          </div>
        </div>
      </>
    );
  }


  return (
    <>
      <SafeAreaHeader
        rightIcon={<CgClose size={24} color={"#060606"} />}
        rightIconOnclick={()=>{
          navigate('/login');
        }}
      />

      <div className="scroll-fixed-area">
        <div className="scroll-container">
          <div className="container">

            <div className="row d-flex">
              <h6 className="profile-title p-1 mb-5 mt-5 text-center">{"Changer le mot de passe"}</h6>
            </div>

            {/*<div className="row  mb-4">*/}
            {/*  <p className="text-center">*/}
            {/*    Veuillez saisir votre E-mail de connexion afin de recevoir*/}
            {/*    <b> le lien de réinitialisation de votre mot de passe.</b>*/}
            {/*  </p>*/}
            {/*</div>*/}


            <div className="row mb-2">
              <Field
                id={"password"}
                name={"password"}
                label={"Nouveau mot de passe"}
                type={"password"}
                value={data.newPassword}
                onChange={(e) => {
                  setData(prevState => ({
                    ...prevState,
                    newPassword: e
                  }))
                }}
              />
            </div>

            <div className="row mb-2">
              <Field
                id={"confirm-password"}
                name={"confirm-password"}
                label={"Confirmation du nouveau mot de passe"}
                type={"password"}
                value={data.confirmedPassword}
                onChange={(e) => {
                  setData(prevState => ({
                    ...prevState,
                    confirmedPassword: e
                  }))
                }}
              />
            </div>


          </div>
          <div className="container mb-4">
            <div className={`btn blue-button`} onClick={async () => {
              await onSubmit()
            }}>
              Enregistrer un nouveau mot de passe
            </div>
          </div>

          <div className="row d-flex mt-5">
            <div className="p-1 text-center">Si vous avez besoin d'assistance</div>
          </div>

          <div className="row d-flex justify-center">
            <Link to={`/support`} className="logo-link" style={{textAlign: 'center'}}>
              veuillez nous contacter
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;

import { IoClose } from "react-icons/io5";
import SafeAreaHeader from "../../components/safeAreaHeader";
import React from "react";
import { TermsAndConditionsComponent } from "../../../doctor/pages/terms-and-conditions";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LuChevronLeft } from "react-icons/lu";
import { PrivacyAndPolicyComponent } from "../../../doctor/pages/privacy-policy";

export const PrivacyAndPolicyWalkthrough = () => {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  }

  return(
    <di>
      <SafeAreaHeader
        leftIcon={<LuChevronLeft  size={24} color={"#555555"} />}
        leftIconOnclick={()=>{
          goBack()
        }}
      />
      <PrivacyAndPolicyComponent />
    </di>
  )
}

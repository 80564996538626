import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Icon, UserAvatar } from "../../../../components/Component";
import User from "../../../../images/avatar/b-sm.jpg";
import EmptyStateImage from "../../../../assets/images/cliconsult/empty-states/search-empty-state.png";
import { LuSearch } from "react-icons/lu";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { ConvertFilterObjectToUrlParam } from "../../../../shared/convertFilterObjectToUrlParam";
import { FiChevronRight, FiMapPin } from "react-icons/fi";
import { setSearchResult, setSelectedItem } from "../../../../redux/store/services/search/store/search-actions";
import { EmptyState } from "../../../../components/fouladyar/empty-state/emptyState";
import { LoadingState } from "../../../../components/fouladyar/loading-state/loadingState";
import toast from "react-hot-toast";
import { setHideMessagesStatus } from "../../../../redux/store/services/profile/store";

const SearchResults = () => {

  const auth = useSelector((state) => state.auth);
  const search = useSelector((state) => state.search);
  const profile = useSelector((state) => state.profile);

  const map = useSelector((state) => state.map);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [hideMessage, setHideMessage] = useState(false);

  /*useSelector((state) => {

    if (state.profile.hideMessageStatus) {
      setHideMessage(true);
    }
  });*/

  async function getCityNameByGeoLocation() {
    try {
      const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${map.latitude},${map.longitude}&libraries=places&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;
      const response = await fetch(apiUrl);
      const data = await response.json();
      if (data.status === "OK") {
        const addressComponents = data.results[0].address_components;
        const cityComponent = addressComponents.find(
          component => component.types.includes("locality")
        );

        if (cityComponent) {
          return cityComponent.long_name;
        } else {
          console.error("City component not found in address components.");
          return null;
        }
      } else {
        console.error("Geocoding API request failed with status:", data.status);
        return null;
      }

    } catch (e) {
      return false;
    }
  }


  async function loadItems() {
    try {
      const params = {
        provider_type: search.mode.toUpperCase(),
        longitude: search.nearMe ? search.myLocation.lng : map.longitude,
        latitude: search.nearMe ? search.myLocation.lat : map.latitude,
        distance: search.nearMe ? 50 : 1000,
        // city: await getCityNameByGeoLocation(),
        city: "",
        name: "",
        languages: search.languages.map(item => {
          return item.value.charAt(0).toUpperCase() + item.value.slice(1).toLowerCase();
        }).join(",") || ""
      };

      if (search.specialization !== "ALL") {
        params["specialization"] = search.specialization.toLowerCase();
      }

      if (search.canDoAbortion) {
        params["can_do_abortion"] = search.canDoAbortion;
      }

      if (search.canDoPediatrics) {
        params["can_do_pediatrics"] = search.canDoPediatrics;
      }

      if (search.canDoUltrasound) {
        params["can_do_ultrasound"] = search.canDoUltrasound;
      }

      if (search.searchBy === "name" && search.text) {
        params["name"] = search.text;
      }

      if (search.searchBy === "location") {
        params["city"] = search.text;
      }

      setIsLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_SEARCH_API_URL}/find/providers/by/${search.searchBy === "location" ? "city" : "name"}?${await ConvertFilterObjectToUrlParam(params)}`, {});

      if (res.status === 200) {
        dispatch(setSearchResult(res.data));
      }

      setIsLoading(false);
    } catch (e) {

      dispatch(setSearchResult([]));
      console.error("Search Fetch Error", e);
      setIsLoading(false);

    }
  }

  useEffect(() => {
    // Function to update screenWidth when the window is resized
    const updateScreenWidth = () => {
      setScreenWidth(window.innerWidth);
    };


    loadItems();
    // Add event listener for window resize
    window.addEventListener("resize", updateScreenWidth);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []);


  useEffect(() => {
    loadItems();
    // dispatch(setSearchResult(searchItems));
  }, [search.mode, search.nearMe]);


  useEffect(() => {
    if (search.selectedItem) {
      console.log("selectedItem", search.selectedItem);
      //selectedItem
      const element = document.getElementById(search.selectedItem.provider_id);
      if (element)
        element.scrollIntoView({ behavior: "smooth" });
    }


    dispatch(setHideMessagesStatus(false));

  }, [search.selectedItem]);


  function SearchBox() {

    // alert("SearchBox runnig..."+ profile.hideMessageStatus);
    console.log("SearchBox runnig...");
    const getSearchBoxClassName = () => {

      const isNativeApp = !!window.ReactNativeWebView;

      const dynamicUp = profile.hideMessageStatus ? "" : isNativeApp ? 'search-box-dynamic-top-native-app': "search-box-dynamic-top";

      return `container ${screenWidth <= 700 ? "search-box-container-fixed " + dynamicUp : "search-box-container-absolute " + dynamicUp}`;
    };

    return (


      <Link to={`${process.env.PUBLIC_URL}/filter`}>
        <div className={getSearchBoxClassName()}>
          <div className="input-group mb-3 search-box">
            <div className="input-group-prepend ps-2">
              <LuSearch size={20} color={"#a9a9a9"} />
            </div>
            <input
              key={"search-box"}
              type="text"
              placeholder={t("")}
              className="form-control form-control-sm"
              aria-label="Search"
              aria-describedby="search-icon-left search-icon-right"
              disabled={false}
              name="search-box"
              defaultValue=""
              onChange={(e) => {
              }}
            />
          </div>
        </div>
      </Link>

    );
  }

  function InnerSearchResults() {
    const GetNextAppointmentTime = ({ time, provider_id }) => {
      return (
        <>
          <Link to={`${process.env.PUBLIC_URL}/doctor-profile/${provider_id}`} className="p-0 m-0">
            {
              !time ? <div className="search-item-time col-sm-auto w-auto">Voir les disponibilités</div> :
                <div className="search-item-time-disable col-sm-auto w-auto">Disponible à {time}</div>
            }
          </Link>

        </>

      );
    };

    function SearchItem({ item, index, onClick }) {
      return (
        <div key={`item-${index}`} id={item.provider_id}
             className={`container search-item-parent w-100 ${item.provider_id === search.selectedItem?.provider_id ? "selected" : ""} p-0`}
             onClick={onClick} style={{ cursor: "pointer" }}>
          <div className="row search-item-row-container search-item-information">
            <div className="col-sm-auto w-auto p-0">
              <UserAvatar className="search-item-avatar"
                          image={item.image ? `${process.env.REACT_APP_S3_BUCKET}/${item.image}` : User} />
            </div>
            <div className="col-sm search-result-text  ps-2 pe-2">
              <h6 className="search-item-title">{item.full_name}</h6>
              <p className="search-item-expertise">{t(item.specialization)}</p>
            </div>
            <div className="col-sm-auto more-about-doctor">
              <Link to={`${process.env.PUBLIC_URL}/doctor-profile/${item.provider_id}`} className="p-0 m-0">
                <FiChevronRight size={19} color={"#fff"} />
              </Link>
            </div>
          </div>

          <div
            className="d-flex flex-row justify-content-between search-item-row-container no-vertical-margin search-item-bottom-border">
            <div className="row search-item-location">
              <div className="col-sm-auto w-auto pe-1">
                <FiMapPin size={16} color={"#555555"} />
              </div>
              <p className="col p-0">{item.address}</p>
            </div>
          </div>
          <div
            className="d-flex flex-row justify-content-between search-item-row-container no-vertical-margin search-item-bottom-border">
            <div className="w-50 doctor-badge col" style={{ paddingLeft: 0 }}>
              <div className="d-flex flex-row flex-wrap">

                {
                  !item.accept_vital_card ? "" :
                    <div key={`${item.provider_id}-acceptVitalCard`} className="search-item-badge">
                      {t("acceptVitalCard")}
                    </div>
                }


                {
                  !item.can_do_pediatrics ? "" :
                    <div key={`${item.provider_id}-canDoPediatrics`} className="search-item-badge">
                      {t("canDoPediatrics")}
                    </div>
                }


                {
                  !item.can_do_abortion ? "" :
                    <div key={`${item.provider_id}-canDoAbortion`} className="search-item-badge">
                      {t("canDoAbortion")}
                    </div>
                }
                {
                  !item.can_do_ultrasound ? "" :
                    <div key={`${item.provider_id}-canDoUltrasound`} className="search-item-badge">
                      {t("canDoUltrasound")}
                    </div>
                }

              </div>
            </div>
          </div>
          <div
            className="d-flex flex-row justify-content-between search-item-row-container no-vertical-margin search-item-bottom-border">
            <GetNextAppointmentTime provider_id={item.provider_id} time={item.time} />
          </div>
        </div>
      );
    }

    return (
      <div className="search-result-container">
        {
          search.items.map((item, index) => {
            return (
              <SearchItem item={item} index={index} onClick={() => {
                dispatch(setSelectedItem(item));
              }} />
            );
          })
        }
      </div>
    );
  }

  return (
    <>
        <SearchBox />
      {
        isLoading ? <LoadingState /> :
          <>
            {
              search.items.length === 0 ?
                <EmptyState className={screenWidth < 1000 ? "custom-empty-state" : ""}
                            title={"Oups ! Aucun résultat n'a été trouvé."} image={EmptyStateImage} /> :
                <InnerSearchResults />
            }
          </>
      }

    </>
  );
};

export default SearchResults;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { UserAvatar } from "../../../../components/Component";
import User from "../../../../images/avatar/b-sm.jpg";
import Logo from "../../../../assets/images/cliconsult/headerLogo.png";
import axios from "axios";
import { useTranslation } from "react-i18next";
import SafeAreaHeader from "../../components/safeAreaHeader";
import { BiChevronLeft } from "react-icons/bi";
import toast from "react-hot-toast";

const DoctorTakeAppointmentStarter = () => {
  const auth = useSelector((state) => state.auth);
  const mode = useSelector((state) => state.search.mode);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [profile, setProfile] = useState({
    themeBackgroundColor: '',
    themeButtonColor: '',
    themeTextColor: '',
    themeNavbarColor: '',
    logo: ''
  });
  const { id } = useParams();

  const [nextAppointmentTime, setNextAppointmentTime] = useState("")

  useEffect(() => {
    setIsLoading(true);

    async function loadProfile() {
      try {

        const profileResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/provider/account/${id}`, {
          headers: { "authorization": `bearer ${auth.token}` }
        });

        if (profileResponse.status === 200) {
          setProfile(profileResponse.data);
        }

        const nextAppointmentTimeResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/provider/appointment/next/${id}`, {
        });

        if (nextAppointmentTimeResponse.status === 200 ) {
          setNextAppointmentTime(nextAppointmentTimeResponse.data.time)
        }


      } catch (e) {
        console.error("Error in find/providers", e);
        toast.error(e.message)
      }
    }

    loadProfile();
  }, []);

  function Profile({ badges }) {

    return (
      <div className="container">

        <div className="row  d-flex flex-row  justify-center align-center">
          <div className="col-sm-auto user-avatar-flex" style={{ paddingLeft: 0 }}>
            <UserAvatar className="logo-avatar"  image={profile?.logo ? `${process.env.REACT_APP_S3_BUCKET}/${profile?.logo}` : Logo  }></UserAvatar>
          </div>
        </div>

        <div className="row  d-flex flex-row pt-2 pb-2 justify-center align-center">
          <div className="col-sm-auto user-avatar-flex" style={{ paddingLeft: 0 }}>
            <UserAvatar className="profile-avatar-big" image={profile?.image ? `${process.env.REACT_APP_S3_BUCKET}/${profile?.image}` : User}></UserAvatar>
          </div>
        </div>

        <div className="row d-flex pt-2 pb-2">
          <h6
            className="profile-title text-center"  style={{color: profile.themeTextColor}}>{`Bienvenue au RDV du Dr. ${profile.firstName} ${profile.lastName}`}</h6>
        </div>
        <p className={` text-center pt-2 pb-2`} style={{ fontSize: 17, color: profile.themeTextColor}}>Cliquez sur commencer pour prendre un RDV
          d'urgence</p>

      </div>
    );
  }


  return (
    <>
      <SafeAreaHeader
        styles={{background: profile.themeBackgroundColor}}
        leftIcon={<BiChevronLeft size={28} color={"#555555"} />}
        leftIconOnclick={() => {
          navigate(-1);
        }}
      />
      <div className="scroll-fixed-area" style={{paddingBottom: "unset"}}>
        <div className="scroll-container" style={{background: profile.themeBackgroundColor, height: "100%"}}>
          <Profile />

          <div style={{background: profile.themeButtonColor }}   className={`btn button-no-color`} onClick={() => {
            // navigate(`/doctor-take-appointment-form/${id}`);
            navigate(`/doctor-take-appointment-form/${id}`,{
              state:{
                themeBackgroundColor: profile.themeBackgroundColor,
                themeButtonColor: profile.themeButtonColor,
                themeTextColor: profile.themeTextColor,
                themeNavbarColor: profile.themeNavbarColor,
                logo: profile.logo,
              }
            });

          }}>
            Commencer <div className={"seyedi_green"}>{nextAppointmentTime}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DoctorTakeAppointmentStarter;

import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import Head from "./head/Head";
import Header from "./header/Header";
import AppRoot from "./global/AppRoot";
import AppMain from "./global/AppMain";
import AppWrap from "./global/AppWrap";
import { useDispatch, useSelector } from "react-redux";
import { GoogleMap, Marker, OverlayView, useJsApiLoader } from "@react-google-maps/api";
import "../assets/scss/patient-layout.scss";
import { BottomNavBar } from "./buttomNavBar/ButtomNavBar";
import { UserAvatar } from "../components/Component";
import { setGeoCenter } from "../redux/store/services/map/store/map-actions";
import User from "../images/avatar/b-sm.jpg";
import {
  changeSearchMode,
  disableMyLocation,
  setMyLocation,
  setSelectedItem
} from "../redux/store/services/search/store/search-actions";
import { setHideMessagesStatus } from "../redux/store/services/profile/store/profile-actions";
import { MdClose } from "react-icons/md";
import Logo from "../images/logo.png";
import LogoDark from "../images/logo-dark.png";
import hospitalIcon from "../assets/images/cliconsult/hospital.svg";
import drugstoreIcon from "../assets/images/cliconsult/drugstore.svg";
import labIcon from "../assets/images/cliconsult/lab.svg";

const colors = {
  doctor: "#008BB9",
  nurse: "#6666D2",
  midwife: "#FF798B"
};

function RenderMap() {


  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries: ["places"],
    region: ["FR"]
  });


  const [center, setCenter] = useState({
    lat: 48.8566,
    lng: 2.3522
  });

  const dispatch = useDispatch();
  const [map, setMap] = useState(null);
  const [mapCenter, setMapCenter] = useState(center);
  const [userLocation, setUserLocation] = useState(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const search = useSelector((state) => state.search);

  const refGoogleMap = useRef();


  useEffect(() => {
    console.log("search", search);
    if ((search.selectedItem?.latitude && search.selectedItem?.longitude) && !search.nearMe) {

      setCenter({
        lat: search.selectedItem.latitude,
        lng: search.selectedItem.longitude
      });
    }


  }, [search.selectedItem, search.nearMe]);


  useEffect(() => {
    const updateScreenWidth = () => {
      setScreenWidth(window.innerWidth);
    };


    // Add event listener for window resize
    window.addEventListener("resize", updateScreenWidth);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", updateScreenWidth);
      window.addEventListener("message", (nativeEvent) => {
        console.log(nativeEvent?.data);
      });
    };


  }, []);


  const options = {
    // Disable specific types of places by setting their respective control options to false
    mapTypeControl: false,
    zoom: 12,
    streetViewControl: false,
    fullscreenControl: false,
    zoomControl: true,
    styles: [
      // You can also customize the map style if needed
      {
        featureType: "poi",
        elementType: "labels",
        stylers: [{ visibility: "off" }]
      }
    ]
  };

  console.log("zoom : ", options.zoom);

  const handleMapLoad = React.useCallback(
    function onLoad(map) {
      setMap(map);
      console.log("map loaded");
      /*const bounds = new window.google.maps.LatLngBounds(center);
      map.fitBounds(bounds);
      map.setOptions({ maxZoom: 19, minZoom: 1 });*/

      console.log("newCenter:", map.getCenter().lat(), map.getCenter().lng());
      refGoogleMap.current = map;
      updatePlaces(map);
    },
    [map]
  );

  const onCenterChangedHandler = React.useCallback(
    function onCenterChanged() {
      console.log(map);

      if (map) {
        const newCenter = map.getCenter();
        console.log(newCenter);
        const lat = newCenter.lat();
        const lng = newCenter.lng();
        console.log("lat: ", lat);
        console.log("lng: ", lng);
      }
    },
    [map]
  );



  const updatePlaces = (map, newCenter) => {

    function hospiatlCallback(results, status, pagination) {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        for (let i = 0; i < results.length; i++) {
          let place = results[i];
          new google.maps.Marker({
            position: place.geometry.location,
            map,
            title: place.name,
            icon: hospitalGoogleMapIcon
          });
        }
      }
    }

    function drugStoreCallback(results, status) {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        for (let i = 0; i < results.length; i++) {
          let place = results[i];
          new google.maps.Marker({
            position: place.geometry.location,
            map,
            title: place.name,
            icon: drugstoreGoogleMapIcon
          });
        }
      }
    }

    function HealthCallback(results, status) {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        for (let i = 0; i < results.length; i++) {
          let place = results[i];
          new google.maps.Marker({
            position: place.geometry.location,
            map,
            title: place.name,
            icon: drugstoreGoogleMapIcon
          });
        }
      }
    }/**/


    const hospitalGoogleMapIcon = {
      url: hospitalIcon, // url
      scaledSize: new google.maps.Size(12, 12), // scaled size
      origin: new google.maps.Point(0, 0), // origin
      anchor: new google.maps.Point(0, 0) // anchor
    };

    const drugstoreGoogleMapIcon = {
      url: drugstoreIcon, // url
      scaledSize: new google.maps.Size(12, 12), // scaled size
      origin: new google.maps.Point(0, 0), // origin
      anchor: new google.maps.Point(0, 0) // anchor
    };


    console.log("newCenter:", map.getCenter().lat(), map.getCenter().lng());
    const service = new window.google.maps.places.PlacesService(map);
    service.nearbySearch({
      location: newCenter,
      radius: "50000",
      type: ["hospital"]
    }, hospiatlCallback);
    
    // request.type = ["drugstore"];
    service.nearbySearch({
      location: center,
      radius: "50000",
      type: ["drugstore"]
    }, drugStoreCallback);

    service.nearbySearch({
      location: center,
      radius: "50000",
      type: ["pharmacy"]
    }, drugStoreCallback);



  };

  const handleMapDragEnd = async () => {

    if (map) {
      const newCenter = await map.getCenter();
      const geo = { lat: await newCenter.lat(), lng: await newCenter.lng() };

      updatePlaces(map, newCenter);

      dispatch(setGeoCenter(geo));
    }
  };

  const CustomMarker = ({ provider_id, image, type, onClick }) => {
    return (
      <div className={`red-marker ${search.selectedItem?.provider_id === provider_id ? "selected" : ""}`}
           onClick={onClick}>
        <svg width="46" height="63" viewBox="0 0 46 63" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M42.9801 33.1542C45.2969 29.339 46.3233 24.8953 45.9109 20.4663C45.3284 14.6673 42.5086 9.31124 38.035 5.50672C33.5614 1.70221 27.7766 -0.259508 21.8778 0.0275974C15.979 0.314703 10.4179 2.82865 6.34554 7.04911C2.27314 11.2696 0.00122032 16.8734 0 22.7008C0 31.6945 5.84053 37.4089 10.9589 44.0711C15.4252 49.8755 19.2464 55.5692 21.7565 62.4113C21.8209 62.584 21.9374 62.733 22.0904 62.8383C22.2434 62.9436 22.4254 63 22.6119 63C22.7984 63 22.9804 62.9436 23.1333 62.8383C23.2863 62.733 23.4029 62.584 23.4673 62.4113C25.9715 56.744 29.1691 51.4004 32.9888 46.4994C34.8889 43.9674 36.8661 41.5045 38.8574 39.0416C40.3886 37.1908 41.7673 35.2221 42.9801 33.1542Z"
            fill={type === "doctor" ? colors.doctor : (type === "nurse" ? colors.nurse : colors.midwife)} />
        </svg>
        <UserAvatar className={"marker-avatar"}
                    image={image ? `${process.env.REACT_APP_S3_BUCKET}/${image}` : User}></UserAvatar>
      </div>
    );
  };

  const handleMarkerClick = (item) => {
    dispatch(setSelectedItem(item));

    // You can perform additional actions here
  };

  const handleLocate = () => {

    if (navigator.geolocation && !search.nearMe) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;


          setCenter({ lat: latitude, lng: longitude });
          dispatch(setMyLocation({ lat: latitude, lng: longitude }));
          setUserLocation({ lat: latitude, lng: longitude });
          // setZoom(15); // You can set a different zoom level for the current location

        },
        (error) => {
          console.error("Error getting current location:", error);
          window.ReactNativeWebView.postMessage(JSON.stringify({
            action: "location",
            message: `Error getting current location: \n\n ${error}`
          }));

        }
      );
    } else {
      dispatch(disableMyLocation());
      console.error("Geolocation is not supported by this browser.");
      window.ReactNativeWebView.postMessage(JSON.stringify({
        action: "location",
        message: `Geolocation is not supported by this browser.`
      }));

    }


  };


  const navigate = useNavigate();

  useEffect(() => {

    dispatch(changeSearchMode("doctor"));
    navigate(`/home`);

  }, []);

  return isLoaded ? (
    <GoogleMap
      ref={refGoogleMap}
      mapContainerClassName={"map-container"}
      center={center}
      onCenterChanged={onCenterChangedHandler}
      onLoad={handleMapLoad}
      onDragEnd={handleMapDragEnd}
      // onZoomChanged={zoomChanged}
      onUnmount={map => {
        // setMap(null)
      }}
      libraries={["places"]}
      options={options}>
      {userLocation && <Marker position={userLocation} title="Your Location" />}
      {
        !search.items ? "" : search.items.map(i => {
          return (
            // <Marker
            //   position={{ lat: i.latitude, lng: i.longitude }}
            //   icon={<CustomMarker text="A"/>}
            // />

            <OverlayView
              position={{ lat: i.latitude, lng: i.longitude }}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
              <CustomMarker provider_id={i.provider_id} image={i.image} type={search.mode} onClick={() => {
                handleMarkerClick(i);
              }} />
            </OverlayView>
          );
        })
      }
      <button className={"btn p-0"} onClick={handleLocate} style={{
        position: "absolute",
        bottom: screenWidth <= 700 ? 30 : 120,
        left: screenWidth <= 700 ? 12 : "unset",
        right: screenWidth <= 700 ? "unset" : 12,
        opacity: search.nearMe ? 1 : 0.4
      }}>
        <svg width="35" height="35" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M27.997 49.5488C39.8997 49.5488 49.5488 39.8997 49.5488 27.997C49.5488 16.0943 39.8997 6.44531 27.997 6.44531C16.0943 6.44531 6.44531 16.0943 6.44531 27.997C6.44531 39.8997 16.0943 49.5488 27.997 49.5488Z"
            stroke="#22247D" stroke-width="4.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M10.7586 28L3 28" stroke="#22247D" stroke-width="4.5" stroke-linecap="round"
                stroke-linejoin="round" />
          <path d="M53.0008 28L45.2422 28" stroke="#22247D" stroke-width="4.5" stroke-linecap="round"
                stroke-linejoin="round" />
          <path d="M28 2.99919V10.7578" stroke="#22247D" stroke-width="4.5" stroke-linecap="round"
                stroke-linejoin="round" />
          <path d="M28 45.2414V53" stroke="#22247D" stroke-width="4.5" stroke-linecap="round"
                stroke-linejoin="round" />
          <circle cx="27.9967" cy="27.9967" r="9.28572" fill="#22247D" />
        </svg>

      </button>
    </GoogleMap>
  ) : <></>;
}


const PatientLayout = ({ title, app, ...props }) => {

  const dispatch = useDispatch();

  const location = useLocation();
  const memoizedMap = useMemo(() => <RenderMap />, []);
  const [showMessage, setShowMessage] = useState(true);
  const [isNativeApp, setIsNativeApp] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {

    /* console.log('navigator.userAgent:',navigator.userAgent.toLowerCase());
     console.log('window.ReactNativeWebView:',window.ReactNativeWebView);*/


    if (window.ReactNativeWebView) {
      // setIsNativeApp(true);
      setShowMessage(false);
    }

    // window.ReactNativeWebView && (navigator.userAgent.toLowerCase().includes("iphone") || navigator.userAgent.toLowerCase().includes("ipad"))) {
    /* const messageListener = window.addEventListener("message", (nativeEvent) => {
       //alert(JSON.stringify(nativeEvent?.data));
       try {

         const nativeRecievedData = JSON.parse(nativeEvent?.data);


         if (nativeRecievedData.Type == "config") {
           setIsNativeApp(nativeRecievedData.isMobileApp);
         }

       } catch (e) {
         console.log("error parse json fron native app:", e);
       }

       //alert(JSON.stringify(nativeEvent?.data));
     });

     return messageListener;*/
  }, []);


  useEffect(() => {
    // Function to update screenWidth when the window is resized
    const updateScreenWidth = () => {
      setScreenWidth(window.innerWidth);
    };


    // Add event listener for window resize
    window.addEventListener("resize", updateScreenWidth);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []);


  const toggleMessagePreviewHandler = () => {
    setShowMessage(false);
    dispatch(setHideMessagesStatus(true));
  };


  return (
    <>
      {
        <>
          <Head title={!title && "Loading"} />
          <AppRoot>
            <AppMain>

              {
                (showMessage && screenWidth <= 700) ?
                  <div
                    className="message-preview d-flex flex-row justify-content-between align-items-center  pe-2 ps-2">

                    <div className="message-preview-left-side" onClick={toggleMessagePreviewHandler}>
                      <MdClose size={22} color={"#555555"} className="me-2" />Vous êtes professionnel de santé ?
                    </div>

                    <div className="message-preview-right-side">
                      <a href={process.env.REACT_APP_DOCTOR_URL} rel="noopener noreferrer"
                         className="message-preview-link">
                        Cliquez ici
                      </a>
                    </div>
                  </div> :
                  <></>
              }

              <AppWrap className={(showMessage && screenWidth <= 700) ? " use-message-box" : ""}>
                {((location.pathname === "/home" && screenWidth <= 700) || (screenWidth > 700)) ? (
                  <>
                    <Header fixed />
                    {memoizedMap}
                  </>
                ) : ""}
                <Outlet />
                <BottomNavBar />
              </AppWrap>
            </AppMain>
          </AppRoot>
        </>
      }

    </>
  );
};
export default PatientLayout;

import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { validateToken } from "../pages/auth";
import { DownloadSegmentComponent } from "../pages/doctor/pages/walkthrough";
import { CheckPlatform } from "../shared/checkPlatform";

const DoctorLayoutWalkthrough = ({ title, app, ...props }) => {

  const location = useLocation();
  const isDownloadRoute = location.pathname === "/download";
  const auth = useSelector((state) => state.auth);
  const [isLoaded, setIsLoaded] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    async function validateAccessToken() {
      const tokenValidation = await validateToken(auth.token);
      if (tokenValidation) {
        navigate(`/home`);
      } else {
        setIsLoaded(true);
      }
    }

    validateAccessToken();
  }, []);


  return (
    <>
      {!isLoaded ?
        <div></div> :
        <div className={"doctor-walkthrough"}>
          <div className="container-fluid">
            <div className="nk-content-inner">
              <div className="nk-content-body">
                <div className="nk-block walkthrough-content-body">
                  {
                    isDownloadRoute ? "" :
                      <div className="left-side-view ">
                        <Outlet />
                      </div>
                  }
                  {
                    (CheckPlatform() !== "mobile" && CheckPlatform() !== "windows" && CheckPlatform() !== "mac") ?
                      <div className="right-side-view " style={{ display: isDownloadRoute ? "block" : "" }}>
                        <DownloadSegment />
                      </div> :
                      <></>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};


const DownloadSegment = () => {
  return (
    <div>
      <DownloadSegmentComponent />
    </div>
  );
};

export default DoctorLayoutWalkthrough;

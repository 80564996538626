import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Field } from "../../../../components/fouladyar/field/field";
import axios from "axios";
import toast from "react-hot-toast";
import { ErrorToaster } from "../../../../shared/toaster";
import SafeAreaHeader from "../../components/safeAreaHeader";
import { BiChevronLeft } from "react-icons/bi";
import { useTranslation } from "react-i18next";

const ContactUsPatient = () => {
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [termsAndConditions, setTermsAndConditions] = useState(false);

  const [data, setData] = useState({
    "email": "",
    "pattern": "",
    "name": "",
    "message": ""

  });

  async function onSubmitForm() {

    try {

      const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/contactus`, data, {
        headers: { authorization: `bearer ${auth.token}` }
      });

      if (res.status === 200) {
        toast.success("Votre message a été envoyé avec succès. \nMerci d'avoir contacté!\n Nous vous répondrons dans les plus brefs délais.");
        navigate(`/home`);
      }

    } catch (e) {
      ErrorToaster(e);
    }
  }


  return (
    <>

      <SafeAreaHeader
        leftIcon={<BiChevronLeft size={28} color={"#555555"} />}
        leftIconOnclick={() => {
          navigate("/my-profile");
        }}
      />

      <div className="scroll-fixed-area">
        <div className="scroll-container">

          <div className="row d-flex mb-3 mt-4">
            <h6 className="profile-title p-1 text-center">Contacter le support</h6>
          </div>

          <div className="container">


            <div className="">
              <Field
                className="mb-2"
                id={"select-pattern"}
                name={"select-pattern"}
                label={t("Sélectionner un motif")}
                type={"select"}
                options={[
                  { label: "Retour", value: "Feedback" },
                  { label: "Rapport d'erreur", value: "Bug report" },
                  { label: "Demande de fonctionnalité", value: "Feature request" },
                  { label: "Problème technique", value: "Technical issue" }
                ]}
                value={data.pattern}
                onChange={(e) => {
                  setData(prevState => ({
                    ...prevState,
                    pattern: e
                  }));
                }}
              />
            </div>
            <div className="">
              <Field
                className="mb-2"
                id={"email"}
                name={"email"}
                label={t("Votre adresse E-mail")}
                type={"text"}
                value={data.email}
                onChange={(e) => {
                  setData(prevState => ({
                    ...prevState,
                    email: e
                  }));
                }}
              />
            </div>
            <div className="">
              <Field
                className="mb-2"
                id={"name"}
                name={"name"}
                label={t("Votre nom")}
                type={"text"}
                value={data.name}
                onChange={(e) => {
                  setData(prevState => ({
                    ...prevState,
                    name: e
                  }));
                }}
              />
            </div>
            <div className="">
              <Field
                className="mb-2"
                id={"message"}
                name={"message"}
                label={"Message"}
                type={"textarea"}
                value={data.message}
                onChange={(e) => {
                  setData(prevState => ({
                    ...prevState,
                    message: e
                  }));
                }}
              />
            </div>
            <div className="mt-3">
              <Field
                id={"resterconnect"}
                name={"resterconnect"}
                label={"En utilisant ce formulaire, j'accepte les conditions d'utilisation et la politique de confidentialité de CLICONSULT pour collecter mes données."}
                type={"checkbox"}
                value={termsAndConditions}
                onChange={(e) => {
                  setTermsAndConditions(e);
                }}
              />
            </div>


          </div>

          <div className="container mb-4 pt-4">
            <div className={`btn blue-button ${!termsAndConditions ? "disabled" : ""}`} onClick={async () => {
              if (termsAndConditions)
                await onSubmitForm();
            }}>
              Soumettre
            </div>


          </div>

        </div>
      </div>
    </>
  );
};

export default ContactUsPatient;
